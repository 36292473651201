import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import format from '@/format';
import { CssClasses } from '@/models/CssClasses';
import { Localization } from '@/models/Localization';
import { computed } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'WinningsText',
  props: {
    value: {},
    showCurrency: { type: Boolean, default: false },
    localization: {}
  },
  setup(__props: any) {

const props = __props;

const formattedValue = computed(() => {
  if (props.showCurrency && props.localization) return format.result(props.value, props.localization);
  return format.resultWithoutCurrency(props.value);
});

const cssClasses = computed((): CssClasses => {
  return {
    'pos-result': props.value > 0,
    'neg-result': props.value < 0,
  };
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass(cssClasses.value)
  }, _toDisplayString(formattedValue.value), 3 /* TEXT, CLASS */))
}
}

})