import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, withCtx as _withCtx, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "buttons" }

import { Layout } from '@/components/Layouts';
import BunchNavigation from '@/components/Navigation/BunchNavigation.vue';
import { Block, CustomButton, PageHeading, PageSection, ErrorMessage } from '@/components/Common';
import CurrencyLayoutDropdown from '@/components/CurrencyLayoutDropdown.vue';
import TimezoneDropdown from '@/components/TimezoneDropdown.vue';
import { ValueList, ValueListKey, ValueListValue } from '@/components/Common/ValueList';
import api from '@/api';
import { ApiParamsUpdateBunch } from '@/models/ApiParamsUpdateBunch';
import { computed, ref } from 'vue';
import { useBunch, useParams } from '@/composables';
import format from '@/format';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { bunchKey, bunchListKey, userBunchListKey } from '@/queries/queryKeys';


export default /*@__PURE__*/_defineComponent({
  __name: 'BunchDetailsPage',
  setup(__props) {

const { slug } = useParams();
const { bunch, isManager, localization, bunchReady } = useBunch(slug.value);
const queryClient = useQueryClient();

const isEditing = ref(false);
const errorMessage = ref<string | null>(null);
const formDescription = ref<string>();
const formHouseRules = ref<string>();
const formDefaultBuyin = ref<number | null>(null);
const formTimezone = ref<string>();
const formCurrencySymbol = ref<string>();
const formCurrencyLayout = ref<string>();

const bunchName = computed(() => {
  return bunch.value.name;
});

const description = computed(() => {
  return bunch.value.description;
});

const hasDescription = computed(() => {
  return !!description.value;
});

const houseRules = computed(() => {
  return bunch.value.houseRules;
});

const hasHouseRules = computed(() => {
  return !!houseRules.value;
});

const defaultBuyin = computed(() => {
  return bunch.value.defaultBuyin;
});

const timezone = computed(() => {
  return bunch.value.timezone;
});

const currencyFormat = computed(() => {
  return format.currency(123, localization.value);
});

const showEditForm = () => {
  formDescription.value = bunch.value.description;
  formHouseRules.value = bunch.value.houseRules;
  formDefaultBuyin.value = bunch.value.defaultBuyin;
  formTimezone.value = bunch.value.timezone;
  formCurrencySymbol.value = bunch.value.currencySymbol;
  formCurrencyLayout.value = bunch.value.currencyLayout;
  isEditing.value = true;
};

const hideEditForm = () => {
  isEditing.value = false;
};

const cancel = () => {
  hideEditForm();
};

const saveMutation = useMutation({
  mutationFn: async () => {
    const postData: ApiParamsUpdateBunch = {
      description: formDescription.value,
      houseRules: formHouseRules.value,
      defaultBuyin: formDefaultBuyin.value,
      timezone: formTimezone.value,
      currencySymbol: formCurrencySymbol.value,
      currencyLayout: formCurrencyLayout.value,
    };

    await api.updateBunch(bunch.value.id, postData);
  },
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: userBunchListKey(true) });
    queryClient.invalidateQueries({ queryKey: bunchListKey() });
    queryClient.invalidateQueries({ queryKey: bunchKey(slug.value) });
    hideEditForm();
  },
  onError: () => {
    errorMessage.value = 'Server error';
  },
});

const save = () => {
  errorMessage.value = null;

  if (!formDefaultBuyin.value) {
    errorMessage.value = 'Please enter a default buyin';
    return;
  }

  if (!formTimezone.value) {
    errorMessage.value = 'Please select a timezone';
    return;
  }

  if (!formCurrencySymbol.value) {
    errorMessage.value = 'Please enter a currency symbol';
    return;
  }

  if (!formCurrencyLayout.value) {
    errorMessage.value = 'Please select a currency layout';
    return;
  }

  saveMutation.mutate();
};

const ready = computed(() => {
  return bunchReady.value;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Layout), {
    "require-user": true,
    ready: ready.value
  }, {
    "top-nav": _withCtx(() => [
      _createVNode(BunchNavigation)
    ]),
    default: _withCtx(() => [
      _createVNode(_unref(PageSection), null, {
        default: _withCtx(() => [
          _createVNode(_unref(Block), null, {
            default: _withCtx(() => [
              _createVNode(_unref(PageHeading), { text: bunchName.value }, null, 8 /* PROPS */, ["text"])
            ]),
            _: 1 /* STABLE */
          }),
          (isEditing.value)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                (_unref(isManager))
                  ? (_openBlock(), _createBlock(_unref(Block), { key: 0 }, {
                      default: _withCtx(() => [
                        _createElementVNode("p", null, [
                          _cache[6] || (_cache[6] = _createElementVNode("label", {
                            class: "label",
                            for: "description"
                          }, "Description", -1 /* HOISTED */)),
                          _withDirectives(_createElementVNode("input", {
                            class: "textfield",
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((formDescription).value = $event)),
                            id: "description",
                            type: "text"
                          }, null, 512 /* NEED_PATCH */), [
                            [_vModelText, formDescription.value]
                          ])
                        ]),
                        _createElementVNode("p", null, [
                          _cache[7] || (_cache[7] = _createElementVNode("label", {
                            class: "label",
                            for: "houseRules"
                          }, "House Rules", -1 /* HOISTED */)),
                          _withDirectives(_createElementVNode("textarea", {
                            class: "textfield",
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((formHouseRules).value = $event)),
                            id: "houseRules"
                          }, null, 512 /* NEED_PATCH */), [
                            [_vModelText, formHouseRules.value]
                          ])
                        ]),
                        _createElementVNode("p", null, [
                          _cache[8] || (_cache[8] = _createElementVNode("label", {
                            class: "label",
                            for: "defaultBuyin"
                          }, "Default buyin", -1 /* HOISTED */)),
                          _withDirectives(_createElementVNode("input", {
                            class: "textfield",
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((formDefaultBuyin).value = $event)),
                            id: "defaultBuyin",
                            type: "text"
                          }, null, 512 /* NEED_PATCH */), [
                            [_vModelText, formDefaultBuyin.value]
                          ])
                        ]),
                        _createElementVNode("p", null, [
                          _cache[9] || (_cache[9] = _createElementVNode("label", {
                            class: "label",
                            for: "timezone"
                          }, "Timezone", -1 /* HOISTED */)),
                          _createVNode(TimezoneDropdown, {
                            modelValue: formTimezone.value,
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((formTimezone).value = $event))
                          }, null, 8 /* PROPS */, ["modelValue"])
                        ]),
                        _createElementVNode("p", null, [
                          _cache[10] || (_cache[10] = _createElementVNode("label", {
                            class: "label",
                            for: "currencySymbol"
                          }, "Currency Symbol", -1 /* HOISTED */)),
                          _withDirectives(_createElementVNode("input", {
                            class: "textfield",
                            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((formCurrencySymbol).value = $event)),
                            id: "currencySymbol",
                            type: "text"
                          }, null, 512 /* NEED_PATCH */), [
                            [_vModelText, formCurrencySymbol.value]
                          ])
                        ]),
                        _createElementVNode("p", null, [
                          _cache[11] || (_cache[11] = _createElementVNode("label", {
                            class: "label",
                            for: "currencyLayout"
                          }, "Currency Layout", -1 /* HOISTED */)),
                          _createVNode(CurrencyLayoutDropdown, {
                            modelValue: formCurrencyLayout.value,
                            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((formCurrencyLayout).value = $event)),
                            symbol: formCurrencySymbol.value
                          }, null, 8 /* PROPS */, ["modelValue", "symbol"])
                        ]),
                        _createVNode(_unref(ErrorMessage), { message: errorMessage.value }, null, 8 /* PROPS */, ["message"]),
                        _createElementVNode("div", _hoisted_1, [
                          _createVNode(_unref(CustomButton), {
                            onClick: save,
                            text: "Save",
                            type: "action"
                          }),
                          _createVNode(_unref(CustomButton), {
                            onClick: cancel,
                            text: "Cancel"
                          })
                        ])
                      ]),
                      _: 1 /* STABLE */
                    }))
                  : _createCommentVNode("v-if", true)
              ], 64 /* STABLE_FRAGMENT */))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                (hasDescription.value)
                  ? (_openBlock(), _createBlock(_unref(Block), { key: 0 }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(description.value), 1 /* TEXT */)
                      ]),
                      _: 1 /* STABLE */
                    }))
                  : _createCommentVNode("v-if", true),
                (hasHouseRules.value)
                  ? (_openBlock(), _createBlock(_unref(Block), { key: 1 }, {
                      default: _withCtx(() => _cache[12] || (_cache[12] = [
                        _createElementVNode("h2", null, "House Rules", -1 /* HOISTED */)
                      ])),
                      _: 1 /* STABLE */
                    }))
                  : _createCommentVNode("v-if", true),
                (hasHouseRules.value)
                  ? (_openBlock(), _createBlock(_unref(Block), { key: 2 }, {
                      default: _withCtx(() => [
                        _createElementVNode("p", null, _toDisplayString(houseRules.value), 1 /* TEXT */)
                      ]),
                      _: 1 /* STABLE */
                    }))
                  : _createCommentVNode("v-if", true),
                _createVNode(_unref(Block), null, {
                  default: _withCtx(() => _cache[13] || (_cache[13] = [
                    _createElementVNode("h2", null, "Settings", -1 /* HOISTED */)
                  ])),
                  _: 1 /* STABLE */
                }),
                _createVNode(_unref(Block), null, {
                  default: _withCtx(() => [
                    _createVNode(_unref(ValueList), null, {
                      default: _withCtx(() => [
                        _createVNode(_unref(ValueListKey), null, {
                          default: _withCtx(() => _cache[14] || (_cache[14] = [
                            _createTextVNode("Default Buyin")
                          ])),
                          _: 1 /* STABLE */
                        }),
                        _createVNode(_unref(ValueListValue), null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(defaultBuyin.value), 1 /* TEXT */)
                          ]),
                          _: 1 /* STABLE */
                        }),
                        _createVNode(_unref(ValueListKey), null, {
                          default: _withCtx(() => _cache[15] || (_cache[15] = [
                            _createTextVNode("Timezone")
                          ])),
                          _: 1 /* STABLE */
                        }),
                        _createVNode(_unref(ValueListValue), null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(timezone.value), 1 /* TEXT */)
                          ]),
                          _: 1 /* STABLE */
                        }),
                        _createVNode(_unref(ValueListKey), null, {
                          default: _withCtx(() => _cache[16] || (_cache[16] = [
                            _createTextVNode("Currency Format")
                          ])),
                          _: 1 /* STABLE */
                        }),
                        _createVNode(_unref(ValueListValue), null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(currencyFormat.value), 1 /* TEXT */)
                          ]),
                          _: 1 /* STABLE */
                        })
                      ]),
                      _: 1 /* STABLE */
                    })
                  ]),
                  _: 1 /* STABLE */
                }),
                (_unref(isManager))
                  ? (_openBlock(), _createBlock(_unref(Block), { key: 3 }, {
                      default: _withCtx(() => [
                        _createVNode(_unref(CustomButton), {
                          onClick: showEditForm,
                          text: "Edit Bunch",
                          type: "action"
                        })
                      ]),
                      _: 1 /* STABLE */
                    }))
                  : _createCommentVNode("v-if", true)
              ], 64 /* STABLE_FRAGMENT */))
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["ready"]))
}
}

})