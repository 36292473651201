import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, vModelCheckbox as _vModelCheckbox, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = ["disabled"]
const _hoisted_3 = { class: "checkbox-layout" }
const _hoisted_4 = ["disabled"]
const _hoisted_5 = { class: "buttons" }
const _hoisted_6 = ["disabled"]
const _hoisted_7 = {
  key: 0,
  class: "login-form__logging-in"
}

import querystring from '@/querystring';
import api from '@/api';
import auth from '@/auth';
import { ApiParamsLogin } from '@/models/ApiParamsLogin';
import { computed, ref } from 'vue';
import { ErrorMessage } from '@/components/Common';
import { useMutation } from '@tanstack/vue-query';


export default /*@__PURE__*/_defineComponent({
  __name: 'LoginForm',
  setup(__props) {

const username = ref('');
const password = ref('');
const rememberMe = ref(false);
const errorMessage = ref<string | null>(null);
const isLoggingIn = computed(() => loginMutation.isPending.value);

const login = async () => {
  clearError();

  if (validateForm()) {
    var data: ApiParamsLogin = {
      username: username.value,
      password: password.value,
    };

    loginMutation.mutate();
  } else {
    showError('Please enter your username (or email) and password');
  }
};

const loginMutation = useMutation({
  mutationFn: async (): Promise<string> => {
    var data: ApiParamsLogin = {
      username: username.value,
      password: password.value,
    };

    var response = await api.login(data);
    return response.data;
  },
  onSuccess: (response) => {
    saveToken(response);
    redirect();
  },
  onError: () => {
    showError('There was something wrong with your username or password. Please try again.');
  },
});

const validateForm = () => {
  clearError();
  if (username.value === '' || password.value === '') return false;
  return true;
};

const clearError = () => {
  errorMessage.value = null;
};

const showError = (message: string) => {
  errorMessage.value = message;
};

const saveToken = (token: string) => {
  auth.setToken(token, rememberMe.value);
};

const redirect = () => {
  const returnUrl = querystring.get('returnurl');
  const redirectUrl = returnUrl || '/';
  window.location.href = redirectUrl;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_unref(ErrorMessage), { message: errorMessage.value }, null, 8 /* PROPS */, ["message"]),
    _createElementVNode("fieldset", null, [
      _createElementVNode("p", null, [
        _cache[3] || (_cache[3] = _createElementVNode("label", {
          class: "label",
          for: "username"
        }, "Email or User Name", -1 /* HOISTED */)),
        _withDirectives(_createElementVNode("input", {
          type: "text",
          id: "username",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((username).value = $event)),
          disabled: isLoggingIn.value,
          class: "textfield"
        }, null, 8 /* PROPS */, _hoisted_1), [
          [_vModelText, username.value]
        ])
      ]),
      _createElementVNode("p", null, [
        _cache[4] || (_cache[4] = _createElementVNode("label", {
          class: "label",
          for: "password"
        }, "Password", -1 /* HOISTED */)),
        _withDirectives(_createElementVNode("input", {
          type: "password",
          id: "password",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((password).value = $event)),
          disabled: isLoggingIn.value,
          class: "textfield"
        }, null, 8 /* PROPS */, _hoisted_2), [
          [_vModelText, password.value]
        ])
      ]),
      _createElementVNode("p", _hoisted_3, [
        _cache[5] || (_cache[5] = _createElementVNode("label", {
          class: "checkbox-label",
          for: "rememberme"
        }, "Keep me signed in", -1 /* HOISTED */)),
        _withDirectives(_createElementVNode("input", {
          type: "checkbox",
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((rememberMe).value = $event)),
          disabled: isLoggingIn.value,
          id: "rememberme"
        }, null, 8 /* PROPS */, _hoisted_4), [
          [_vModelCheckbox, rememberMe.value]
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("button", {
          onClick: login,
          class: "button button--action",
          disabled: isLoggingIn.value
        }, "Sign in", 8 /* PROPS */, _hoisted_6),
        (isLoggingIn.value)
          ? (_openBlock(), _createElementBlock("span", _hoisted_7, "Signing in..."))
          : _createCommentVNode("v-if", true)
      ])
    ])
  ]))
}
}

})