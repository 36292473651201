import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import PlayerResultFact from './PlayerResultFact.vue';
import PlayerAmountFact from './PlayerAmountFact.vue';
import PlayerTimeFact from './PlayerTimeFact.vue';
import { DefinitionList, DefinitionTerm } from '@/components/Common/DefinitionList';
import { CashgameListPlayerData } from '@/models/CashgameListPlayerData';
import { TotalFactCollection } from '@/models/TotalFactCollection';
import { computed } from 'vue';
import { ArchiveCashgame } from '@/models/ArchiveCashgame';
import archiveHelper from '@/ArchiveHelper';
import playerSorter from '@/PlayerSorter';
import { Localization } from '@/models/Localization';


export default /*@__PURE__*/_defineComponent({
  __name: 'TotalFacts',
  props: {
    games: {},
    localization: {}
  },
  setup(__props: any) {

//Things to add
//MostGamesPlayed
//HighestWinrate

const props = __props;

const facts = computed(() => getFacts(players.value));
const players = computed(() => playerSorter.sort(archiveHelper.getPlayers(props.games)));

const getFacts = (players: CashgameListPlayerData[]): TotalFactCollection => {
  var mostTime = { name: '', id: '0', minutes: 0 };
  var bestTotal = { name: '', id: '0', amount: 0 };
  var worstTotal = { name: '', id: '0', amount: 0 };
  var biggestBuyin = { name: '', id: '0', amount: 0 };
  var biggestCashout = { name: '', id: '0', amount: 0 };
  for (var pi = 0; pi < players.length; pi++) {
    var player = players[pi];
    if (player.playedTimeInMinutes > mostTime.minutes) {
      mostTime = { name: player.name, id: player.id, minutes: player.playedTimeInMinutes };
    }
    if (player.winnings > bestTotal.amount) {
      bestTotal = { name: player.name, id: player.id, amount: player.winnings };
    }
    if (player.winnings < worstTotal.amount) {
      worstTotal = { name: player.name, id: player.id, amount: player.winnings };
    }
    if (player.buyin > biggestBuyin.amount) {
      biggestBuyin = { name: player.name, id: player.id, amount: player.buyin };
    }
    if (player.stack > biggestCashout.amount) {
      biggestCashout = { name: player.name, id: player.id, amount: player.stack };
    }
  }
  return {
    mostTime: mostTime,
    bestTotal: bestTotal,
    worstTotal: worstTotal,
    biggestBuyin: biggestBuyin,
    biggestCashout: biggestCashout,
  };
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _cache[5] || (_cache[5] = _createElementVNode("h2", { class: "h2" }, "Totals", -1 /* HOISTED */)),
    _createVNode(_unref(DefinitionList), null, {
      default: _withCtx(() => [
        _createVNode(_unref(DefinitionTerm), null, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode("Most Time Played")
          ])),
          _: 1 /* STABLE */
        }),
        _createVNode(PlayerTimeFact, {
          name: facts.value.mostTime.name,
          minutes: facts.value.mostTime.minutes
        }, null, 8 /* PROPS */, ["name", "minutes"]),
        _createVNode(_unref(DefinitionTerm), null, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode("Best Total Result")
          ])),
          _: 1 /* STABLE */
        }),
        _createVNode(PlayerResultFact, {
          name: facts.value.bestTotal.name,
          amount: facts.value.bestTotal.amount,
          localization: _ctx.localization
        }, null, 8 /* PROPS */, ["name", "amount", "localization"]),
        _createVNode(_unref(DefinitionTerm), null, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [
            _createTextVNode("Worst Total Result")
          ])),
          _: 1 /* STABLE */
        }),
        _createVNode(PlayerResultFact, {
          name: facts.value.worstTotal.name,
          amount: facts.value.worstTotal.amount,
          localization: _ctx.localization
        }, null, 8 /* PROPS */, ["name", "amount", "localization"]),
        _createVNode(_unref(DefinitionTerm), null, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [
            _createTextVNode("Biggest Total Buyin")
          ])),
          _: 1 /* STABLE */
        }),
        _createVNode(PlayerAmountFact, {
          name: facts.value.biggestBuyin.name,
          amount: facts.value.biggestBuyin.amount,
          localization: _ctx.localization
        }, null, 8 /* PROPS */, ["name", "amount", "localization"]),
        _createVNode(_unref(DefinitionTerm), null, {
          default: _withCtx(() => _cache[4] || (_cache[4] = [
            _createTextVNode("Biggest Total Cashout")
          ])),
          _: 1 /* STABLE */
        }),
        _createVNode(PlayerAmountFact, {
          name: facts.value.biggestCashout.name,
          amount: facts.value.biggestCashout.amount,
          localization: _ctx.localization
        }, null, 8 /* PROPS */, ["name", "amount", "localization"])
      ]),
      _: 1 /* STABLE */
    })
  ]))
}
}

})