import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "field" }
const _hoisted_2 = { class: "buttons" }

import { Layout } from '@/components/Layouts';
import BunchNavigation from '@/components/Navigation/BunchNavigation.vue';
import { Block, CustomButton, ErrorMessage, PageHeading, PageSection } from '@/components/Common';
import { ApiParamsAddCashgame } from '@/models/ApiParamsAddCashgame';
import urls from '@/urls';
import api from '@/api';
import { AxiosError } from 'axios';
import { ApiError } from '@/models/ApiError';
import LocationDropdown from '@/components/LocationDropdown.vue';
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useLocationList, useParams } from '@/composables';
import { useMutation } from '@tanstack/vue-query';
import { DetailedCashgameResponse } from '@/response/DetailedCashgameResponse';


export default /*@__PURE__*/_defineComponent({
  __name: 'AddCashgamePage',
  setup(__props) {

const { slug } = useParams();
const router = useRouter();
const { locations, locationsReady } = useLocationList(slug.value);

const locationId = ref('');
const errorMessage = ref('');

const add = async () => {
  errorMessage.value = '';

  if (locationId.value === '') {
    errorMessage.value = 'Please select a location.';
    return;
  }

  addMutation.mutate();
};

const addMutation = useMutation({
  mutationFn: async () => {
    const params: ApiParamsAddCashgame = {
      locationId: locationId.value,
    };

    const response = await api.addCashgame(slug.value, params);
    return response.data;
  },
  onSuccess: (data: DetailedCashgameResponse) => {
    redirectToGame(data.id);
  },
  onError: (error: AxiosError<ApiError>) => {
    const message = error.response?.data.message || 'Unknown Error';
    errorMessage.value = message;
  },
});

const cancel = () => {
  history.back();
};

const redirectToGame = (id: string) => {
  router.push(urls.cashgame.details(slug.value, id));
};

const ready = computed(() => locationsReady.value);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Layout), {
    "require-user": true,
    ready: ready.value
  }, {
    "top-nav": _withCtx(() => [
      _createVNode(BunchNavigation)
    ]),
    default: _withCtx(() => [
      _createVNode(_unref(PageSection), null, {
        default: _withCtx(() => [
          _createVNode(_unref(Block), null, {
            default: _withCtx(() => [
              _createVNode(_unref(PageHeading), { text: "Start Cashgame" })
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_unref(Block), null, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _cache[1] || (_cache[1] = _createElementVNode("label", {
                  class: "label",
                  for: "locationId"
                }, "Location", -1 /* HOISTED */)),
                _createVNode(LocationDropdown, {
                  locations: _unref(locations),
                  modelValue: locationId.value,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((locationId).value = $event))
                }, null, 8 /* PROPS */, ["locations", "modelValue"])
              ]),
              _createVNode(_unref(ErrorMessage), { message: errorMessage.value }, null, 8 /* PROPS */, ["message"]),
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_unref(CustomButton), {
                  onClick: add,
                  type: "action",
                  text: "Start"
                }),
                _createVNode(_unref(CustomButton), {
                  onClick: cancel,
                  text: "Cancel"
                })
              ])
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["ready"]))
}
}

})