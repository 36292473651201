import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "user-nav"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

import { CustomLink } from '@/components/Common';
import urls from '@/urls';
import auth from '@/auth';
import { computed } from 'vue';
import { useCurrentUser } from '@/composables';


export default /*@__PURE__*/_defineComponent({
  __name: 'UserNavigation',
  setup(__props) {

const { isSignedIn, currentUser, currentUserReady } = useCurrentUser();

const logOut = () => {
  auth.clearToken();
  redirectHome();
};

const userReady = computed(() => currentUserReady.value);
const displayName = computed(() => currentUser.value?.displayName);
const userDetailsUrl = computed(() => (currentUser.value ? urls.user.details(currentUser.value.userName) : ''));
const registerUrl = computed(() => urls.user.add);
const resetPasswordUrl = computed(() => urls.user.resetPassword);
const loginUrl = computed(() => urls.auth.login);

const redirectHome = () => {
  window.location.href = urls.home;
};

return (_ctx: any,_cache: any) => {
  return (userReady.value)
    ? (_openBlock(), _createElementBlock("nav", _hoisted_1, [
        _cache[4] || (_cache[4] = _createElementVNode("h2", null, "Account", -1 /* HOISTED */)),
        (_unref(isSignedIn))
          ? (_openBlock(), _createElementBlock("ul", _hoisted_2, [
              _createElementVNode("li", null, [
                _createVNode(_unref(CustomLink), { url: userDetailsUrl.value }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, "Signed in as " + _toDisplayString(displayName.value), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["url"])
              ]),
              _createElementVNode("li", null, [
                _createElementVNode("a", {
                  href: "#",
                  onClick: _withModifiers(logOut, ["prevent"])
                }, _cache[0] || (_cache[0] = [
                  _createElementVNode("span", null, "Sign Out", -1 /* HOISTED */)
                ]))
              ])
            ]))
          : (_openBlock(), _createElementBlock("ul", _hoisted_3, [
              _createElementVNode("li", null, [
                _createVNode(_unref(CustomLink), { url: loginUrl.value }, {
                  default: _withCtx(() => _cache[1] || (_cache[1] = [
                    _createElementVNode("span", null, "Sign in", -1 /* HOISTED */)
                  ])),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["url"])
              ]),
              _createElementVNode("li", null, [
                _createVNode(_unref(CustomLink), { url: registerUrl.value }, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createElementVNode("span", null, "Register", -1 /* HOISTED */)
                  ])),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["url"])
              ]),
              _createElementVNode("li", null, [
                _createVNode(_unref(CustomLink), { url: resetPasswordUrl.value }, {
                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                    _createElementVNode("span", null, "Reset password", -1 /* HOISTED */)
                  ])),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["url"])
              ])
            ]))
      ]))
    : _createCommentVNode("v-if", true)
}
}

})