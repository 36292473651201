import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import urls from '@/urls';
import { CustomLink } from '@/components/Common';
import { LocationResponse } from '@/response/LocationResponse';
import { computed } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'LocationListItem',
  props: {
    location: {},
    bunchId: {}
  },
  setup(__props: any) {

const props = __props;

const name = computed(() => {
  return props.location.name;
});

const url = computed(() => {
  return urls.location.details(props.bunchId, props.location.id.toString());
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(CustomLink), { url: url.value }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(name.value), 1 /* TEXT */)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["url"]))
}
}

})