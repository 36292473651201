import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, withCtx as _withCtx, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "field" }
const _hoisted_2 = { class: "buttons" }

import { Layout } from '@/components/Layouts';
import BunchNavigation from '@/components/Navigation/BunchNavigation.vue';
import { Block, CustomButton, ErrorMessage, PageHeading, PageSection } from '@/components/Common';
import urls from '@/urls';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { useParams } from '@/composables';
import api from '@/api';
import { locationListKey } from '@/queries/queryKeys';


export default /*@__PURE__*/_defineComponent({
  __name: 'AddLocationPage',
  setup(__props) {

const { slug } = useParams();
const router = useRouter();
const queryClient = useQueryClient();

const locationName = ref('');
const errorMessage = ref('');

const addMutation = useMutation({
  mutationFn: async () => {
    const response = await api.addLocation(slug.value, { name: locationName.value });
    return response.data;
  },
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: locationListKey(slug.value) });
    redirect();
  },
  onError: () => {
    errorMessage.value = 'Server error';
  },
});

const add = () => {
  if (locationName.value.length > 0) {
    addMutation.mutate();
    redirect();
  } else {
    errorMessage.value = "Name can't be empty";
  }
};

const cancel = () => {
  redirect();
};

const redirect = () => {
  router.push(urls.location.list(slug.value));
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Layout), {
    "require-user": true,
    ready: true
  }, {
    "top-nav": _withCtx(() => [
      _createVNode(BunchNavigation)
    ]),
    default: _withCtx(() => [
      _createVNode(_unref(PageSection), null, {
        default: _withCtx(() => [
          _createVNode(_unref(Block), null, {
            default: _withCtx(() => [
              _createVNode(_unref(PageHeading), { text: "Add Location" })
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_unref(Block), null, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _cache[1] || (_cache[1] = _createElementVNode("label", {
                  class: "label",
                  for: "location-name"
                }, "Name", -1 /* HOISTED */)),
                _withDirectives(_createElementVNode("input", {
                  class: "textfield",
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((locationName).value = $event)),
                  id: "location-name",
                  type: "text"
                }, null, 512 /* NEED_PATCH */), [
                  [_vModelText, locationName.value]
                ])
              ]),
              _createVNode(_unref(ErrorMessage), { message: errorMessage.value }, null, 8 /* PROPS */, ["message"]),
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_unref(CustomButton), {
                  onClick: add,
                  type: "action",
                  text: "Add"
                }),
                _createVNode(_unref(CustomButton), {
                  onClick: cancel,
                  text: "Cancel"
                })
              ])
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }))
}
}

})