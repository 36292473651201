import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "totals" }
const _hoisted_2 = { class: "amounts" }
const _hoisted_3 = { class: "amount" }
const _hoisted_4 = { class: "amount" }

import PlayerRow from './PlayerRow.vue';
import { CurrencyText } from '@/components/Common';
import cashgameHelper from '@/CashgameHelper';
import { computed } from 'vue';
import { DetailedCashgamePlayer } from '@/models/DetailedCashgamePlayer';
import { BuyinIcon, InlineIcon, ReportIcon } from '../Icons';
import { Localization } from '@/models/Localization';
import { SaveActionEmitData } from '@/models/SaveActionEmitData';


export default /*@__PURE__*/_defineComponent({
  __name: 'PlayerTable',
  props: {
    bunchId: {},
    players: {},
    isCashgameRunning: { type: Boolean },
    canEdit: { type: Boolean },
    localization: {}
  },
  emits: ["playerSelected", "saveAction", "deleteAction"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const totalBuyin = computed(() => cashgameHelper.getTotalBuyin(props.players));
const totalStacks = computed(() => cashgameHelper.getTotalStacks(props.players));
const onSelected = (id: string) => emit('playerSelected', id);
const onDeleteAction = (id: string) => emit('deleteAction', id);
const onSaveAction = (data: SaveActionEmitData) => emit('saveAction', data);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.players, (player) => {
      return (_openBlock(), _createElementBlock("div", {
        key: player.id
      }, [
        _createVNode(PlayerRow, {
          player: player,
          isCashgameRunning: _ctx.isCashgameRunning,
          isReportTimeEnabled: _ctx.isCashgameRunning,
          onSelected: onSelected,
          onDeleteAction: onDeleteAction,
          onSaveAction: onSaveAction,
          canEdit: _ctx.canEdit,
          bunchId: _ctx.bunchId,
          localization: _ctx.localization
        }, null, 8 /* PROPS */, ["player", "isCashgameRunning", "isReportTimeEnabled", "canEdit", "bunchId", "localization"])
      ]))
    }), 128 /* KEYED_FRAGMENT */)),
    _createElementVNode("div", _hoisted_1, [
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "title" }, "Totals:", -1 /* HOISTED */)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_unref(InlineIcon), null, {
            default: _withCtx(() => [
              _createVNode(_unref(BuyinIcon), { title: "Total Buy in" })
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_unref(CurrencyText), {
            value: totalBuyin.value,
            localization: _ctx.localization
          }, null, 8 /* PROPS */, ["value", "localization"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_unref(InlineIcon), null, {
            default: _withCtx(() => [
              _createVNode(_unref(ReportIcon), { title: "Total Stacks" })
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_unref(CurrencyText), {
            value: totalStacks.value,
            localization: _ctx.localization
          }, null, 8 /* PROPS */, ["value", "localization"])
        ])
      ])
    ])
  ]))
}
}

})