import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

import urls from '@/urls';
import MatrixItem from './MatrixItem.vue';
import { CustomLink } from '@/components/Common';
import { TableListCell, TableListRow } from '@/components/Common/TableList';
import { WinningsText } from '@/components/Common';
import { CashgameListPlayerData } from '@/models/CashgameListPlayerData';
import { computed } from 'vue';
import { Localization } from '@/models/Localization';


export default /*@__PURE__*/_defineComponent({
  __name: 'MatrixRow',
  props: {
    bunchId: {},
    player: {},
    index: {},
    localization: {}
  },
  setup(__props: any) {

const props = __props;

const url = computed(() => urls.player.details(props.bunchId, props.player.id));
const name = computed(() => props.player.name);
const rank = computed(() => props.index + 1);
const winnings = computed(() => props.player.winnings);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(TableListRow), null, {
    default: _withCtx(() => [
      _createVNode(_unref(TableListCell), { "is-numeric": true }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(rank.value) + ".", 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_unref(TableListCell), null, {
        default: _withCtx(() => [
          _createVNode(_unref(CustomLink), { url: url.value }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(name.value), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["url"])
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_unref(TableListCell), { "is-numeric": true }, {
        default: _withCtx(() => [
          _createVNode(_unref(WinningsText), {
            value: winnings.value,
            "show-currency": true,
            localization: _ctx.localization
          }, null, 8 /* PROPS */, ["value", "localization"])
        ]),
        _: 1 /* STABLE */
      }),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.player.gameResults, (game) => {
        return (_openBlock(), _createBlock(MatrixItem, {
          game: game,
          localization: _ctx.localization,
          key: game.gameId
        }, null, 8 /* PROPS */, ["game", "localization"]))
      }), 128 /* KEYED_FRAGMENT */))
    ]),
    _: 1 /* STABLE */
  }))
}
}

})