import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "matrix" }

import YearMatrixColumn from './YearMatrixColumn.vue';
import YearMatrixRow from './YearMatrixRow.vue';
import { CashgamePlayerData } from '@/models/CashgamePlayerData';
import { CashgamePlayerYearlyResultCollection } from '@/models/CashgamePlayerYearlyResultCollection';
import dayjs from 'dayjs';
import { TableList, TableListColumnHeader } from '@/components/Common/TableList';
import { computed } from 'vue';
import { BunchResponse } from '@/response/BunchResponse';
import { ArchiveCashgame } from '@/models/ArchiveCashgame';
import ArchiveHelper from '@/ArchiveHelper';
import { Localization } from '@/models/Localization';


export default /*@__PURE__*/_defineComponent({
  __name: 'YearMatrixTable',
  props: {
    bunch: {},
    games: {},
    localization: {}
  },
  setup(__props: any) {

const props = __props;

const bunchId = computed(() => {
  return props.bunch.id;
});

const years = computed(() => {
  return ArchiveHelper.getYears(props.games);
});

const currentYear = computed(() => {
  return ArchiveHelper.getCurrentYear(props.games);
});

const players = computed(() => {
  return ArchiveHelper.getPlayers(props.games);
});

const playersWithYearResults = computed((): CashgamePlayerYearlyResultCollection[] => {
  var matrixArray: CashgamePlayerYearlyResultCollection[] = [];
  if (!currentYear.value) return matrixArray;
  for (let i = 0; i < players.value.length; i++) {
    const player = players.value[i];
    const mostRecentGame = getMostRecentGame(player.gameResults);
    const buyinTime = mostRecentGame?.buyinTime;
    const yearOfMostRecentGame: number | null = !!buyinTime ? dayjs(buyinTime).year() : null;
    if (yearOfMostRecentGame === currentYear.value) {
      var playerYears = [];
      for (let k = 0; k < years.value.length; k++) {
        var year = years.value[k];
        var yearGames = getGamesForYear(player.gameResults, year);
        var playerYear = {
          year: year,
          winnings: 0,
          playedThisYear: yearGames.length > 0,
        };
        for (let j = 0; j < yearGames.length; j++) {
          var game = yearGames[j];
          playerYear.winnings += game.winnings;
        }
        playerYears.push(playerYear);
      }
      var matrixPlayer = {
        id: player.id,
        name: player.name,
        winnings: player.winnings,
        years: playerYears,
      };
      matrixArray.push(matrixPlayer);
    }
  }
  return matrixArray;
});

const getMostRecentGame = (games: CashgamePlayerData[]) => {
  for (let i = 0; i < games.length; i++) {
    if (games[i] && games[i].playedThisGame) {
      return games[i];
    }
  }
  return null;
};

const getGamesForYear = (games: CashgamePlayerData[], year: number) => {
  var yearGames = [];
  for (let i = 0; i < games.length; i++) {
    var game = games[i];
    if (!!game && game.playedThisGame && !!game.buyinTime && dayjs(game.buyinTime).year() === year) {
      yearGames.push(game);
    }
  }
  return yearGames;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(TableList), null, {
      default: _withCtx(() => [
        _createElementVNode("thead", null, [
          _createElementVNode("tr", null, [
            _createVNode(_unref(TableListColumnHeader)),
            _createVNode(_unref(TableListColumnHeader), null, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode("Player")
              ])),
              _: 1 /* STABLE */
            }),
            _createVNode(_unref(TableListColumnHeader), null, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createTextVNode("Winnings")
              ])),
              _: 1 /* STABLE */
            }),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(years.value, (year) => {
              return (_openBlock(), _createBlock(YearMatrixColumn, {
                year: year,
                key: year
              }, null, 8 /* PROPS */, ["year"]))
            }), 128 /* KEYED_FRAGMENT */))
          ])
        ]),
        _createElementVNode("tbody", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(playersWithYearResults.value, (player, index) => {
            return (_openBlock(), _createBlock(YearMatrixRow, {
              player: player,
              index: index,
              key: player.id,
              bunchId: bunchId.value,
              localization: _ctx.localization
            }, null, 8 /* PROPS */, ["player", "index", "bunchId", "localization"]))
          }), 128 /* KEYED_FRAGMENT */))
        ])
      ]),
      _: 1 /* STABLE */
    })
  ]))
}
}

})