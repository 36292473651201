import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, unref as _unref, withCtx as _withCtx, createBlock as _createBlock } from "vue"

import { SimpleList, SimpleListItem } from '@/components/Common/SimpleList';
import BunchListItem from '@/components/BunchList/BunchListItem.vue';
import { BunchResponse } from '@/response/BunchResponse';


export default /*@__PURE__*/_defineComponent({
  __name: 'BunchList',
  props: {
    bunches: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(SimpleList), null, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bunches, (bunch) => {
        return (_openBlock(), _createBlock(_unref(SimpleListItem), {
          key: bunch.id
        }, {
          default: _withCtx(() => [
            _createVNode(BunchListItem, {
              id: bunch.id,
              name: bunch.name
            }, null, 8 /* PROPS */, ["id", "name"])
          ]),
          _: 2 /* DYNAMIC */
        }, 1024 /* DYNAMIC_SLOTS */))
      }), 128 /* KEYED_FRAGMENT */))
    ]),
    _: 1 /* STABLE */
  }))
}
}

})