<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="32"
      d="M96 256h320M96 176h320M96 336h320"
    />
  </svg>
</template>

<script setup lang="ts"></script>
