import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { Layout } from '@/components/Layouts';
import LoginForm from '@/components/LoginForm.vue';
import { Block, CustomLink, PageHeading, PageSection } from '@/components/Common';
import urls from '@/urls';
import { computed, onMounted, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useCurrentUser } from '@/composables';


export default /*@__PURE__*/_defineComponent({
  __name: 'LoginPage',
  setup(__props) {

const router = useRouter();

const { isSignedIn, currentUserReady } = useCurrentUser();

const resetPasswordUrl = computed(() => {
  return urls.user.resetPassword;
});

const registerUrl = computed(() => {
  return urls.user.add;
});

const redirectIfSignedIn = () => {
  if (isSignedIn.value) {
    router.push(urls.home);
  }
};

onMounted(() => {
  redirectIfSignedIn();
});

watch(isSignedIn, redirectIfSignedIn);
watch(currentUserReady, redirectIfSignedIn);

const ready = computed(() => {
  return currentUserReady.value;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Layout), {
    "require-user": false,
    ready: ready.value
  }, {
    default: _withCtx(() => [
      _createVNode(_unref(PageSection), null, {
        default: _withCtx(() => [
          _createVNode(_unref(Block), null, {
            default: _withCtx(() => [
              _createVNode(_unref(PageHeading), { text: "Sign in" }),
              _createElementVNode("p", null, [
                _cache[1] || (_cache[1] = _createTextVNode("Please sign in using your username and password. ")),
                _createVNode(_unref(CustomLink), { url: resetPasswordUrl.value }, {
                  default: _withCtx(() => _cache[0] || (_cache[0] = [
                    _createTextVNode("Forgot password?")
                  ])),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["url"])
              ]),
              _createElementVNode("p", null, [
                _cache[3] || (_cache[3] = _createTextVNode("If you are a new user, please ")),
                _createVNode(_unref(CustomLink), { url: registerUrl.value }, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createTextVNode("register")
                  ])),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["url"]),
                _cache[4] || (_cache[4] = _createTextVNode("!"))
              ]),
              _createVNode(LoginForm)
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["ready"]))
}
}

})