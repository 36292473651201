import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "button button--icon" }
const _hoisted_2 = { class: "button__icon" }


export default /*@__PURE__*/_defineComponent({
  __name: 'GameButton',
  props: {
    text: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("button", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _createTextVNode(" " + _toDisplayString(_ctx.text), 1 /* TEXT */)
  ]))
}
}

})