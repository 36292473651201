import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "validation-error"
}

import urls from '@/urls';
import api from '@/api';
import { Layout } from '@/components/Layouts';
import { Block, CustomButton, CustomLink, PageHeading, PageSection } from '@/components/Common';
import { AxiosError } from 'axios';
import { ApiError } from '@/models/ApiError';
import { ApiParamsAddUser } from '@/models/ApiParamsAddUser';
import { computed, ref } from 'vue';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { userListKey } from '@/queries/queryKeys';


export default /*@__PURE__*/_defineComponent({
  __name: 'AddUserPage',
  setup(__props) {

const queryClient = useQueryClient();

const userName = ref('');
const displayName = ref('');
const email = ref('');
const password = ref('');
const repeatPassword = ref('');
const errorMessage = ref('');
const userAdded = ref(false);

const hasError = computed(() => {
  return !!errorMessage.value;
});

const loginUrl = computed(() => {
  return urls.auth.login;
});

const save = async () => {
  if (repeatPassword.value !== password.value) {
    errorMessage.value = "Passwords doesn't match";
    return;
  }

  addUserMutation.mutate();
};

const addUserMutation = useMutation({
  mutationFn: async () => {
    errorMessage.value = '';

    const params: ApiParamsAddUser = {
      userName: userName.value,
      displayName: displayName.value,
      email: email.value,
      password: password.value,
    };

    const response = await api.addUser(params);
    return response.data;
  },
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: userListKey() });
    userAdded.value = true;
  },
  onError: (err) => {
    const error = err as AxiosError<ApiError>;
    errorMessage.value = error.response?.data.message || 'Unknown Error';
  },
});

const back = () => {
  history.back();
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Layout), {
    "require-user": false,
    ready: true
  }, {
    default: _withCtx(() => [
      _createVNode(_unref(PageSection), null, {
        default: _withCtx(() => [
          _createVNode(_unref(Block), null, {
            default: _withCtx(() => [
              _createVNode(_unref(PageHeading), { text: "Register" })
            ]),
            _: 1 /* STABLE */
          }),
          (userAdded.value)
            ? (_openBlock(), _createBlock(_unref(Block), { key: 0 }, {
                default: _withCtx(() => [
                  _cache[7] || (_cache[7] = _createElementVNode("p", null, "Welcome to Poker Bunch!", -1 /* HOISTED */)),
                  _createElementVNode("p", null, [
                    _createVNode(_unref(CustomLink), { url: loginUrl.value }, {
                      default: _withCtx(() => _cache[5] || (_cache[5] = [
                        _createTextVNode("Sign in here!")
                      ])),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["url"]),
                    _cache[6] || (_cache[6] = _createTextVNode(" GL!"))
                  ])
                ]),
                _: 1 /* STABLE */
              }))
            : (_openBlock(), _createBlock(_unref(Block), { key: 1 }, {
                default: _withCtx(() => [
                  _createElementVNode("p", null, [
                    _cache[8] || (_cache[8] = _createElementVNode("label", {
                      class: "label",
                      for: "userName"
                    }, "Login Name", -1 /* HOISTED */)),
                    _withDirectives(_createElementVNode("input", {
                      class: "textfield",
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((userName).value = $event)),
                      id: "userName",
                      type: "text"
                    }, null, 512 /* NEED_PATCH */), [
                      [_vModelText, userName.value]
                    ])
                  ]),
                  _createElementVNode("p", null, [
                    _cache[9] || (_cache[9] = _createElementVNode("label", {
                      class: "label",
                      for: "displayName"
                    }, "Display Name", -1 /* HOISTED */)),
                    _withDirectives(_createElementVNode("input", {
                      class: "textfield",
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((displayName).value = $event)),
                      id: "displayName",
                      type: "text"
                    }, null, 512 /* NEED_PATCH */), [
                      [_vModelText, displayName.value]
                    ])
                  ]),
                  _createElementVNode("p", null, [
                    _cache[10] || (_cache[10] = _createElementVNode("label", {
                      class: "label",
                      for: "email"
                    }, "Email", -1 /* HOISTED */)),
                    _withDirectives(_createElementVNode("input", {
                      class: "textfield",
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((email).value = $event)),
                      id: "email",
                      type: "email"
                    }, null, 512 /* NEED_PATCH */), [
                      [_vModelText, email.value]
                    ])
                  ]),
                  _createElementVNode("p", null, [
                    _cache[11] || (_cache[11] = _createElementVNode("label", {
                      class: "label",
                      for: "password"
                    }, "Password", -1 /* HOISTED */)),
                    _withDirectives(_createElementVNode("input", {
                      class: "textfield",
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((password).value = $event)),
                      id: "password",
                      type: "password"
                    }, null, 512 /* NEED_PATCH */), [
                      [_vModelText, password.value]
                    ])
                  ]),
                  _createElementVNode("p", null, [
                    _cache[12] || (_cache[12] = _createElementVNode("label", {
                      class: "label",
                      for: "repeatPassword"
                    }, "Repeat Password", -1 /* HOISTED */)),
                    _withDirectives(_createElementVNode("input", {
                      class: "textfield",
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((repeatPassword).value = $event)),
                      id: "repeatPassword",
                      type: "password"
                    }, null, 512 /* NEED_PATCH */), [
                      [_vModelText, repeatPassword.value]
                    ])
                  ]),
                  (hasError.value)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(errorMessage.value), 1 /* TEXT */))
                    : _createCommentVNode("v-if", true),
                  _createElementVNode("p", null, [
                    _createVNode(_unref(CustomButton), {
                      onClick: save,
                      type: "action",
                      text: "Save"
                    }),
                    _createVNode(_unref(CustomButton), {
                      onClick: back,
                      text: "Cancel"
                    })
                  ])
                ]),
                _: 1 /* STABLE */
              }))
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }))
}
}

})