import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "matrix__value"
}

import { CashgamePlayerData } from '@/models/CashgamePlayerData';
import { TableListCell } from '@/components/Common/TableList';
import { WinningsText } from '@/components/Common';
import { computed } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'OverviewItem',
  props: {
    game: {}
  },
  setup(__props: any) {

const props = __props;

const winnings = computed(() => {
  return props.game.winnings;
});

const isInGame = computed(() => {
  return props.game.playedThisGame;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(TableListCell), { "is-numeric": true }, {
    default: _withCtx(() => [
      (isInGame.value)
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
            _createVNode(_unref(WinningsText), { value: winnings.value }, null, 8 /* PROPS */, ["value"])
          ]))
        : _createCommentVNode("v-if", true)
    ]),
    _: 1 /* STABLE */
  }))
}
}

})