import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { Layout } from '@/components/Layouts';
import BunchNavigation from '@/components/Navigation/BunchNavigation.vue';
import LocationList from '@/components/LocationList/LocationList.vue';
import { Block, CustomButton, PageHeading, PageSection } from '@/components/Common';
import urls from '@/urls';
import { computed } from 'vue';
import { useLocationList, useParams } from '@/composables';


export default /*@__PURE__*/_defineComponent({
  __name: 'LocationListPage',
  setup(__props) {

const { slug } = useParams();
const { locations, locationsReady } = useLocationList(slug.value);

const addLocationUrl = computed(() => {
  return urls.location.add(slug.value);
});

const ready = computed(() => {
  return locationsReady.value;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Layout), {
    "require-user": true,
    ready: ready.value
  }, {
    "top-nav": _withCtx(() => [
      _createVNode(BunchNavigation)
    ]),
    default: _withCtx(() => [
      _createVNode(_unref(PageSection), null, {
        aside1: _withCtx(() => [
          _createVNode(_unref(Block), null, {
            default: _withCtx(() => [
              _createVNode(_unref(CustomButton), {
                url: addLocationUrl.value,
                type: "action",
                text: "Add location"
              }, null, 8 /* PROPS */, ["url"])
            ]),
            _: 1 /* STABLE */
          })
        ]),
        default: _withCtx(() => [
          _createVNode(_unref(Block), null, {
            default: _withCtx(() => [
              _createVNode(_unref(PageHeading), { text: "Locations" })
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_unref(Block), null, {
            default: _withCtx(() => [
              _createVNode(LocationList, {
                slug: _unref(slug),
                locations: _unref(locations)
              }, null, 8 /* PROPS */, ["slug", "locations"])
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["ready"]))
}
}

})