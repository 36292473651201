import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import TableListColumnHeader from '@/components/Common/TableList/TableListColumnHeader.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'YearMatrixColumn',
  props: {
    year: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(TableListColumnHeader, null, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.year), 1 /* TEXT */)
    ]),
    _: 1 /* STABLE */
  }))
}
}

})