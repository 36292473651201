import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, unref as _unref, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

import { SimpleList, SimpleListItem } from '@/components/Common/SimpleList';
import { computed } from 'vue';
import UserBunchListItem from './UserBunchListItem.vue';
import { BunchResponse } from '@/response/BunchResponse';


export default /*@__PURE__*/_defineComponent({
  __name: 'UserBunchList',
  props: {
    bunches: {}
  },
  setup(__props: any) {

const props = __props;

const userBunches = computed(() => {
  return props.bunches;
});

const hasBunches = computed(() => {
  return props.bunches.length > 0;
});

return (_ctx: any,_cache: any) => {
  return (hasBunches.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _cache[0] || (_cache[0] = _createElementVNode("h1", { class: "module-heading" }, "Your Bunches", -1 /* HOISTED */)),
        _createVNode(_unref(SimpleList), null, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(userBunches.value, (bunch) => {
              return (_openBlock(), _createBlock(_unref(SimpleListItem), {
                key: bunch.id
              }, {
                default: _withCtx(() => [
                  _createVNode(UserBunchListItem, {
                    id: bunch.id,
                    name: bunch.name
                  }, null, 8 /* PROPS */, ["id", "name"])
                ]),
                _: 2 /* DYNAMIC */
              }, 1024 /* DYNAMIC_SLOTS */))
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          _: 1 /* STABLE */
        })
      ]))
    : _createCommentVNode("v-if", true)
}
}

})