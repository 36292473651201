import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "game-nav"
}

import { CustomLink } from '@/components/Common';
import { useBunch, useParams, useUserBunchList } from '@/composables';
import urls from '@/urls';
import { computed } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'BunchNavigation',
  setup(__props) {

const { slug } = useParams();
const { bunch, bunchReady } = useBunch(slug.value);
const { userBunches, userBunchesReady } = useUserBunchList(true);

const calculatedSlug = computed(() => {
  if (slug.value) return slug.value;
  if (userBunchesReady.value && userBunches.value.length > 0) return userBunches.value[0].id;
  return '';
});

const bunchName = computed(() => {
  if (slug.value && bunchReady.value && bunch.value.name.length > 0) return bunch.value.name;
  if (userBunchesReady.value && userBunches.value.length > 0) return userBunches.value[0].name;
  return '';
});

const hasSlug = computed(() => {
  return !!calculatedSlug.value;
});

const bunchDetailsUrl = computed(() => urls.bunch.details(calculatedSlug.value));
const cashgamesUrl = computed(() => urls.cashgame.index(calculatedSlug.value));
const playersUrl = computed(() => urls.player.list(calculatedSlug.value));
const eventsUrl = computed(() => urls.event.list(calculatedSlug.value));
const locationsUrl = computed(() => urls.location.list(calculatedSlug.value));

return (_ctx: any,_cache: any) => {
  return (hasSlug.value)
    ? (_openBlock(), _createElementBlock("nav", _hoisted_1, [
        _createElementVNode("h2", null, [
          _createVNode(_unref(CustomLink), { url: bunchDetailsUrl.value }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(bunchName.value), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["url"])
        ]),
        _createElementVNode("ul", null, [
          _createElementVNode("li", null, [
            _createVNode(_unref(CustomLink), { url: cashgamesUrl.value }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createElementVNode("span", null, "Cashgames", -1 /* HOISTED */)
              ])),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["url"])
          ]),
          _createElementVNode("li", null, [
            _createVNode(_unref(CustomLink), { url: playersUrl.value }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createElementVNode("span", null, "Players", -1 /* HOISTED */)
              ])),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["url"])
          ]),
          _createElementVNode("li", null, [
            _createVNode(_unref(CustomLink), { url: eventsUrl.value }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createElementVNode("span", null, "Events", -1 /* HOISTED */)
              ])),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["url"])
          ]),
          _createElementVNode("li", null, [
            _createVNode(_unref(CustomLink), { url: locationsUrl.value }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createElementVNode("span", null, "Locations", -1 /* HOISTED */)
              ])),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["url"])
          ])
        ])
      ]))
    : _createCommentVNode("v-if", true)
}
}

})