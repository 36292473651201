<template>
  <span class="inline-icon"><slot></slot></span>
</template>

<script setup lang="ts"></script>

<style lang="scss">
.inline-icon {
  display: inline-block;
  vertical-align: middle;
  width: 1rem;
  color: #000;
}
</style>
