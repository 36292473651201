import { defineComponent as _defineComponent } from 'vue'
import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import urls from '@/urls';
import { CustomLink } from '@/components/Common';
import { computed } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'PlayerListItem',
  props: {
    bunchId: {},
    id: {},
    name: {},
    color: {}
  },
  setup(__props: any) {

const props = __props;

const url = computed(() => {
  return urls.player.details(props.bunchId, props.id);
});

const colorBoxStyle = computed(() => {
  return `background-color: ${props.color}`;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("span", {
      class: "player-color-box",
      style: _normalizeStyle(colorBoxStyle.value)
    }, null, 4 /* STYLE */),
    _createVNode(_unref(CustomLink), { url: url.value }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.name), 1 /* TEXT */)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["url"])
  ]))
}
}

})