import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "top-list" }
const _hoisted_2 = { class: "list" }

import TopListRow from './TopListRow.vue';
import { TableList, TableListColumnHeader } from '@/components/Common/TableList';
import { ArchiveCashgame } from '@/models/ArchiveCashgame';
import playerSorter from '@/PlayerSorter';
import archiveHelper from '@/ArchiveHelper';
import { computed, ref } from 'vue';
import { CashgamePlayerSortOrder } from '@/models/CashgamePlayerSortOrder';
import { Localization } from '@/models/Localization';


export default /*@__PURE__*/_defineComponent({
  __name: 'TopListTable',
  props: {
    bunchId: {},
    games: {},
    localization: {}
  },
  setup(__props: any) {

const props = __props;

const players = computed(() => {
  return playerSorter.sort(archiveHelper.getPlayers(props.games), orderedBy.value);
});

const orderedBy = ref(CashgamePlayerSortOrder.Winnings);

const sort = (column: CashgamePlayerSortOrder) => {
  orderedBy.value = column;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(TableList), null, {
      default: _withCtx(() => [
        _createElementVNode("thead", null, [
          _createElementVNode("tr", null, [
            _createVNode(_unref(TableListColumnHeader)),
            _createVNode(_unref(TableListColumnHeader), null, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode("Player")
              ])),
              _: 1 /* STABLE */
            }),
            _createVNode(_unref(TableListColumnHeader), {
              "ordered-by": orderedBy.value,
              onSort: sort,
              "sort-name": "winnings"
            }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createTextVNode("Winnings")
              ])),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["ordered-by"]),
            _createVNode(_unref(TableListColumnHeader), {
              "ordered-by": orderedBy.value,
              onSort: sort,
              "sort-name": "buyin"
            }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createTextVNode("Buyin")
              ])),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["ordered-by"]),
            _createVNode(_unref(TableListColumnHeader), {
              "ordered-by": orderedBy.value,
              onSort: sort,
              "sort-name": "stack"
            }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode("Cashout")
              ])),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["ordered-by"]),
            _createVNode(_unref(TableListColumnHeader), {
              "ordered-by": orderedBy.value,
              onSort: sort,
              "sort-name": "time"
            }, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createTextVNode("Time")
              ])),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["ordered-by"]),
            _createVNode(_unref(TableListColumnHeader), {
              "ordered-by": orderedBy.value,
              onSort: sort,
              "sort-name": "gamecount"
            }, {
              default: _withCtx(() => _cache[5] || (_cache[5] = [
                _createTextVNode("Games")
              ])),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["ordered-by"]),
            _createVNode(_unref(TableListColumnHeader), {
              "ordered-by": orderedBy.value,
              onSort: sort,
              "sort-name": "winrate"
            }, {
              default: _withCtx(() => _cache[6] || (_cache[6] = [
                _createTextVNode("Win rate")
              ])),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["ordered-by"])
          ])
        ]),
        _createElementVNode("tbody", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(players.value, (player) => {
            return (_openBlock(), _createBlock(TopListRow, {
              player: player,
              key: player.id,
              bunchId: _ctx.bunchId,
              localization: _ctx.localization
            }, null, 8 /* PROPS */, ["player", "bunchId", "localization"]))
          }), 128 /* KEYED_FRAGMENT */))
        ])
      ]),
      _: 1 /* STABLE */
    })
  ]))
}
}

})