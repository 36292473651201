import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "cashgame-navigation__item" }
const _hoisted_2 = ["href"]

import { CssClasses } from '@/models/CssClasses';
import { computed } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'CashgameNavigationItem',
  props: {
    url: {},
    text: {},
    isSelected: { type: Boolean }
  },
  emits: ['selected'],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const selectedClass = computed((): CssClasses => {
  return {
    selected: props.isSelected,
  };
});

const click = () => {
  emit('selected', props.url);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    _createElementVNode("a", {
      href: _ctx.url,
      class: _normalizeClass(selectedClass.value),
      onClick: _withModifiers(click, ["prevent"])
    }, _toDisplayString(_ctx.text), 11 /* TEXT, CLASS, PROPS */, _hoisted_2)
  ]))
}
}

})