import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { CustomLink } from '@/components/Common';
import TableListColumnHeader from '@/components/Common/TableList/TableListColumnHeader.vue';
import urls from '@/urls';
import { ArchiveCashgame } from '@/models/ArchiveCashgame';
import format from '@/format';
import { computed } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'MatrixColumn',
  props: {
    game: {},
    slug: {}
  },
  setup(__props: any) {

const props = __props;

const url = computed(() => urls.cashgame.details(props.slug, props.game.id));
const displayDate = computed(() => format.monthDay(props.game.startTime));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(TableListColumnHeader, null, {
    default: _withCtx(() => [
      _createVNode(_unref(CustomLink), { url: url.value }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(displayDate.value), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["url"])
    ]),
    _: 1 /* STABLE */
  }))
}
}

})