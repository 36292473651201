import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

import { Layout } from '@/components/Layouts';
import { Block, PageHeading, PageSection } from '@/components/Common';
import SendEmail from '@/components/Admin/SendEmail.vue';
import ClearCache from '@/components/Admin/ClearCache.vue';
import { computed } from 'vue';
import { useCurrentUser } from '@/composables';


export default /*@__PURE__*/_defineComponent({
  __name: 'AdminToolsPage',
  setup(__props) {

const { isAdmin, currentUserReady } = useCurrentUser();

const ready = computed(() => {
  return currentUserReady.value;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Layout), {
    "require-user": true,
    ready: ready.value
  }, {
    default: _withCtx(() => [
      _createVNode(_unref(PageSection), null, {
        default: _withCtx(() => [
          _createVNode(_unref(Block), null, {
            default: _withCtx(() => [
              _createVNode(_unref(PageHeading), { text: "Admin Tools" })
            ]),
            _: 1 /* STABLE */
          }),
          (_unref(isAdmin))
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createVNode(_unref(Block), null, {
                  default: _withCtx(() => [
                    _createVNode(ClearCache)
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_unref(Block), null, {
                  default: _withCtx(() => [
                    _createVNode(SendEmail)
                  ]),
                  _: 1 /* STABLE */
                })
              ], 64 /* STABLE_FRAGMENT */))
            : (_openBlock(), _createBlock(_unref(Block), { key: 1 }, {
                default: _withCtx(() => _cache[0] || (_cache[0] = [
                  _createElementVNode("p", null, "Access denied", -1 /* HOISTED */)
                ])),
                _: 1 /* STABLE */
              }))
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["ready"]))
}
}

})