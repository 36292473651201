import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import urls from '@/urls';
import { Layout } from '@/components/Layouts';
import { Block, CustomLink, PageHeading, PageSection } from '@/components/Common';
import { computed } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'NotFoundPage',
  setup(__props) {

const homeUrl = computed(() => {
  return urls.home;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Layout), {
    "require-user": false,
    ready: true
  }, {
    default: _withCtx(() => [
      _createVNode(_unref(PageSection), null, {
        default: _withCtx(() => [
          _createVNode(_unref(Block), null, {
            default: _withCtx(() => [
              _createVNode(_unref(PageHeading), { text: "Page not found" })
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_unref(Block), null, {
            default: _withCtx(() => [
              _cache[1] || (_cache[1] = _createElementVNode("p", null, "Please check the url for errors", -1 /* HOISTED */)),
              _createElementVNode("p", null, [
                _cache[0] || (_cache[0] = _createTextVNode("Go to ")),
                _createVNode(_unref(CustomLink), { url: homeUrl.value }, null, 8 /* PROPS */, ["url"])
              ])
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }))
}
}

})