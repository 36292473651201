import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "matrix" }

import urls from '@/urls';
import OverviewRow from '@/components/Overview/OverviewRow.vue';
import { CustomLink } from '@/components/Common';
import { TableList, TableListColumnHeader } from '@/components/Common/TableList';
import { computed } from 'vue';
import { useParams } from '@/composables';
import { ArchiveCashgame } from '@/models/ArchiveCashgame';
import archiveHelper from '@/ArchiveHelper';
import playerSorter from '@/PlayerSorter';
import { BunchResponse } from '@/response/BunchResponse';
import { CashgameListPlayerData } from '@/models/CashgameListPlayerData';
import { CashgamePlayerSortOrder } from '@/models/CashgamePlayerSortOrder';
import { Localization } from '@/models/Localization';


export default /*@__PURE__*/_defineComponent({
  __name: 'OverviewTable',
  props: {
    bunch: {},
    games: {},
    localization: {}
  },
  setup(__props: any) {

const props = __props;

const { slug } = useParams();

const url = computed(() => {
  return urls.cashgame.details(slug.value, lastGame.value.id);
});

const lastGame = computed(() => {
  return props.games[0];
});

const players = computed((): CashgameListPlayerData[] => {
  return playerSorter.sort(archiveHelper.getPlayers(props.games), CashgamePlayerSortOrder.Winnings);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(TableList), null, {
      default: _withCtx(() => [
        _createElementVNode("thead", null, [
          _createElementVNode("tr", null, [
            _createVNode(_unref(TableListColumnHeader)),
            _createVNode(_unref(TableListColumnHeader), null, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode("Player")
              ])),
              _: 1 /* STABLE */
            }),
            _createVNode(_unref(TableListColumnHeader), null, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createTextVNode("Total")
              ])),
              _: 1 /* STABLE */
            }),
            _createVNode(_unref(TableListColumnHeader), null, {
              default: _withCtx(() => [
                _createVNode(_unref(CustomLink), { url: url.value }, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createTextVNode("Last game")
                  ])),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["url"])
              ]),
              _: 1 /* STABLE */
            })
          ])
        ]),
        _createElementVNode("tbody", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(players.value, (player, index) => {
            return (_openBlock(), _createBlock(OverviewRow, {
              player: player,
              index: index,
              bunchId: _unref(slug),
              localization: _ctx.localization,
              key: player.id
            }, null, 8 /* PROPS */, ["player", "index", "bunchId", "localization"]))
          }), 128 /* KEYED_FRAGMENT */))
        ])
      ]),
      _: 1 /* STABLE */
    })
  ]))
}
}

})