import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href"]

import { CssClasses } from '@/models/CssClasses';
import { useRouter } from 'vue-router';
import { computed } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'CustomLink',
  props: {
    url: { default: '' },
    cssClasses: { default: () => {
      return {};
    } }
  },
  setup(__props: any) {

const router = useRouter();

const props = __props;

const isInRouter = computed(() => {
  if (!props.url) return false;
  let resolved = router.resolve(props.url);
  return resolved && resolved.name != '404';
});

const checkedUrl = computed(() => {
  return props.url || '#';
});

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (isInRouter.value)
    ? (_openBlock(), _createBlock(_component_router_link, {
        key: 0,
        to: checkedUrl.value,
        class: _normalizeClass(_ctx.cssClasses)
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3 /* FORWARDED */
      }, 8 /* PROPS */, ["to", "class"]))
    : (_openBlock(), _createElementBlock("a", {
        key: 1,
        href: checkedUrl.value,
        class: _normalizeClass(_ctx.cssClasses)
      }, [
        _renderSlot(_ctx.$slots, "default")
      ], 10 /* CLASS, PROPS */, _hoisted_1))
}
}

})