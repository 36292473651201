import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "matrix__value" }
const _hoisted_2 = { class: "matrix__transaction" }
const _hoisted_3 = { class: "matrix__transaction" }

import { CashgamePlayerData } from '@/models/CashgamePlayerData';
import { CssClasses } from '@/models/CssClasses';
import { TableListCell } from '@/components/Common/TableList';
import { WinningsText } from '@/components/Common';
import { computed } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'MatrixItem',
  props: {
    game: {}
  },
  setup(__props: any) {

const props = __props;

const buyin = computed(() => props.game.buyin);
const stack = computed(() => props.game.stack);
const winnings = computed(() => props.game.winnings);
const isInGame = computed(() => props.game.playedThisGame);

const winnerClass = computed((): CssClasses => {
  return {
    matrix__winner: props.game.isWinner,
  };
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(TableListCell), {
    "is-numeric": true,
    "custom-classes": winnerClass.value
  }, {
    default: _withCtx(() => [
      (isInGame.value)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createElementVNode("span", _hoisted_1, [
              _createVNode(_unref(WinningsText), {
                value: winnings.value,
                showCurrency: false
              }, null, 8 /* PROPS */, ["value"])
            ]),
            _createElementVNode("span", _hoisted_2, "in " + _toDisplayString(buyin.value), 1 /* TEXT */),
            _createElementVNode("span", _hoisted_3, "out " + _toDisplayString(stack.value), 1 /* TEXT */)
          ], 64 /* STABLE_FRAGMENT */))
        : _createCommentVNode("v-if", true)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["custom-classes"]))
}
}

})