import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import urls from '@/urls';
import { CustomLink } from '@/components/Common';
import { computed } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'BunchListItem',
  props: {
    id: {},
    name: {}
  },
  setup(__props: any) {

const props = __props;

const url = computed(() => {
  return urls.bunch.details(props.id);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_unref(CustomLink), { url: url.value }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.name), 1 /* TEXT */)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["url"])
  ]))
}
}

})