import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import PlayerResultFact from './PlayerResultFact.vue';
import { DefinitionList, DefinitionTerm } from '@/components/Common/DefinitionList';
import { CashgameListPlayerData } from '@/models/CashgameListPlayerData';
import { SingleGameFactCollection } from '@/models/SingleGameFactCollection';
import { PlayerWinningsFact } from '@/models/PlayerWinningsFact';
import { computed } from 'vue';
import { ArchiveCashgame } from '@/models/ArchiveCashgame';
import ArchiveHelper from '@/ArchiveHelper';
import { Localization } from '@/models/Localization';


export default /*@__PURE__*/_defineComponent({
  __name: 'SingleGameFacts',
  props: {
    games: {},
    localization: {}
  },
  setup(__props: any) {

//Things to add
//BiggestBuyin
//BiggestCashout
//BiggestComeback

const props = __props;

const facts = computed(() => {
  return getFacts(players.value);
});

const players = computed(() => {
  return ArchiveHelper.getPlayers(props.games);
});

const getFacts = (players: CashgameListPlayerData[]): SingleGameFactCollection => {
  var bestResult: PlayerWinningsFact = { name: '', id: '0', amount: 0 };
  var worstResult: PlayerWinningsFact = { name: '', id: '0', amount: 0 };
  for (var pi = 0; pi < players.length; pi++) {
    var player = players[pi];
    for (var gi = 0; gi < player.gameResults.length; gi++) {
      var game = player.gameResults[gi];
      if (game && game.winnings > bestResult.amount) {
        bestResult = { name: player.name, id: player.id, amount: game.winnings };
      }
      if (game && game.winnings < worstResult.amount) {
        worstResult = { name: player.name, id: player.id, amount: game.winnings };
      }
    }
  }
  return {
    bestResult: bestResult,
    worstResult: worstResult,
  };
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _cache[2] || (_cache[2] = _createElementVNode("h2", { class: "h2" }, "Single Game", -1 /* HOISTED */)),
    _createVNode(_unref(DefinitionList), null, {
      default: _withCtx(() => [
        _createVNode(_unref(DefinitionTerm), null, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode("Best Result")
          ])),
          _: 1 /* STABLE */
        }),
        _createVNode(PlayerResultFact, {
          name: facts.value.bestResult.name,
          amount: facts.value.bestResult.amount,
          localization: _ctx.localization
        }, null, 8 /* PROPS */, ["name", "amount", "localization"]),
        _createVNode(_unref(DefinitionTerm), null, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode("Worst Result")
          ])),
          _: 1 /* STABLE */
        }),
        _createVNode(PlayerResultFact, {
          name: facts.value.worstResult.name,
          amount: facts.value.worstResult.amount,
          localization: _ctx.localization
        }, null, 8 /* PROPS */, ["name", "amount", "localization"])
      ]),
      _: 1 /* STABLE */
    })
  ]))
}
}

})