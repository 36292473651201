import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { CssClasses } from '@/models/CssClasses';
import { computed } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'TableListCell',
  props: {
    isNumeric: { type: Boolean, default: false },
    customClasses: { default: () => {
      return {};
    } }
  },
  setup(__props: any) {

const props = __props;

const cssClasses = computed((): CssClasses => {
  return {
    ...props.customClasses,
    'table-list__cell--numeric': props.isNumeric,
  };
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("td", {
    class: _normalizeClass(["table-list__cell", cssClasses.value])
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 2 /* CLASS */))
}
}

})