import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "errors"
}
const _hoisted_2 = { class: "validation-error" }

import { computed } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ErrorMessage',
  props: {
    message: {}
  },
  setup(__props: any) {

const props = __props;

const isVisible = computed(() => Boolean(props.message));

return (_ctx: any,_cache: any) => {
  return (isVisible.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.message), 1 /* TEXT */)
      ]))
    : _createCommentVNode("v-if", true)
}
}

})