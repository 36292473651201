import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

import { CssClasses } from '@/models/CssClasses';
import { computed, useSlots } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'TableListColumnHeader',
  props: {
    sortName: {},
    orderedBy: {}
  },
  emits: ['sort'],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const slots = useSlots();

const hasContent = computed(() => !!slots.default);
const isSelected = computed(() => props.sortName === props.orderedBy);
const isSortable = computed(() => !!props.sortName);

const cssClasses = computed((): CssClasses => {
  return {
    'table-list__column-header--sortable': isSortable.value,
    'table-list__column-header--selected': isSortable.value && isSelected.value,
  };
});

const onClick = () => {
  if (isSortable.value) emit('sort', props.sortName);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("th", {
    class: _normalizeClass(["table-list__column-header", cssClasses.value])
  }, [
    (hasContent.value)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: "table-list__column-header__content",
          onClick: onClick
        }, [
          _renderSlot(_ctx.$slots, "default")
        ]))
      : _createCommentVNode("v-if", true)
  ], 2 /* CLASS */))
}
}

})