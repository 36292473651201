import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import urls from '@/urls';
import { computed } from 'vue';
import { Block, CustomButton } from '../Common';
import { useParams } from '@/composables';
import { CurrentGameResponse } from '@/response/CurrentGameResponse';


export default /*@__PURE__*/_defineComponent({
  __name: 'OverviewStatus',
  props: {
    games: {}
  },
  setup(__props: any) {

const props = __props;

const { slug } = useParams();

const url = computed(() => {
  return gameIsRunning.value ? runningGameUrl.value : addGameUrl.value;
});

const addGameUrl = computed(() => {
  return urls.cashgame.add(slug.value);
});

const runningGameUrl = computed(() => {
  return urls.cashgame.details(slug.value, runningGameId.value);
});

const runningGameId = computed(() => {
  if (props.games.length === 0) return '0';
  return props.games[0].id;
});

const gameIsRunning = computed(() => {
  return props.games.length > 0;
});

const linkText = computed((): string => {
  return gameIsRunning.value ? 'Go to game' : 'Start a game';
});

const description = computed((): string => {
  return gameIsRunning.value ? 'There is a game running' : 'No game is running at the moment';
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_unref(Block), null, {
      default: _withCtx(() => [
        _cache[0] || (_cache[0] = _createElementVNode("h1", { class: "module-heading" }, "Current Game", -1 /* HOISTED */)),
        _createElementVNode("p", null, _toDisplayString(description.value), 1 /* TEXT */)
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode(_unref(Block), null, {
      default: _withCtx(() => [
        _createVNode(_unref(CustomButton), {
          url: url.value,
          text: linkText.value,
          type: "action"
        }, null, 8 /* PROPS */, ["url", "text"])
      ]),
      _: 1 /* STABLE */
    })
  ]))
}
}

})