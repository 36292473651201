import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, unref as _unref, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { DefinitionData } from '@/components/Common/DefinitionList';
import { DurationText } from '@/components/Common';


export default /*@__PURE__*/_defineComponent({
  __name: 'PlayerTimeFact',
  props: {
    name: {},
    minutes: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(DefinitionData), null, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.name) + ": ", 1 /* TEXT */),
      _createVNode(_unref(DurationText), { value: _ctx.minutes }, null, 8 /* PROPS */, ["value"])
    ]),
    _: 1 /* STABLE */
  }))
}
}

})