import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "page-header" }
const _hoisted_2 = { class: "logo" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "main clearfix" }
const _hoisted_6 = { key: 1 }

import UserNavigation from '@/components/Navigation/UserNavigation.vue';
import { CustomLink, LoadingSpinner, PageSection } from '@/components/Common';
import urls from '@/urls';
import { computed, useSlots, watch } from 'vue';
import { CssClasses } from '@/models/CssClasses';
import { useRoute } from 'vue-router';
import { useCurrentUser } from '@/composables';


export default /*@__PURE__*/_defineComponent({
  __name: 'Layout',
  props: {
    ready: { type: Boolean },
    requireUser: { type: Boolean }
  },
  setup(__props: any) {

const route = useRoute();
const props = __props;

const { isSignedIn, currentUserReady } = useCurrentUser();

const slots = useSlots();

const isTopNavEnabled = computed(() => {
  return isSlotEnabled('top-nav');
});

const homeUrl = computed(() => {
  return urls.home;
});

const logoCssClasses = computed((): CssClasses => {
  return {
    'logo-link': true,
  };
});

const isSlotEnabled = (name: string) => {
  return !!slots[name];
};

const redirectIfSignedOut = () => {
  if (props.requireUser && currentUserReady.value && !isSignedIn.value)
    window.location.href = `${urls.auth.login}?returnurl=${route.fullPath}`;
};

watch(isSignedIn, redirectIfSignedOut);
watch(currentUserReady, redirectIfSignedOut);
watch(() => props.requireUser, redirectIfSignedOut);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_unref(PageSection), null, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_unref(CustomLink), {
              url: homeUrl.value,
              cssClasses: logoCssClasses.value
            }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode("Poker Bunch")
              ])),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["url", "cssClasses"])
          ]),
          (isTopNavEnabled.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _renderSlot(_ctx.$slots, "top-nav")
              ]))
            : _createCommentVNode("v-if", true)
        ])
      ]),
      _: 3 /* FORWARDED */
    }),
    (_ctx.ready)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _renderSlot(_ctx.$slots, "default")
          ]),
          _createVNode(_unref(PageSection), null, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "bottom-nav", {}, () => [
                _createVNode(UserNavigation)
              ])
            ]),
            _: 3 /* FORWARDED */
          })
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(_unref(LoadingSpinner))
        ]))
  ]))
}
}

})