import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "heading-nav" }
const _hoisted_2 = { class: "page-heading" }

import CashgameNavigationItem from './CashgameNavigationItem.vue';
import { CashgamePage } from '@/models/CashgamePage';
import YearDropdown from '@/components/YearDropdown.vue';
import urls from '@/urls';
import { computed, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useGameList, useParams } from '@/composables';


export default /*@__PURE__*/_defineComponent({
  __name: 'CashgameNavigation',
  props: {
    page: {}
  },
  setup(__props: any) {

const props = __props;

const { slug, year } = useParams();
const route = useRoute();
const router = useRouter();

const selectedYear = ref<number>();
const { years, currentYear } = useGameList(slug.value);

const selectedPageName = computed(() => {
  if (props.page === 'matrix') return 'Matrix';
  if (props.page === 'toplist') return 'Toplist';
  if (props.page === 'chart') return 'Chart';
  if (props.page === 'list') return 'List';
  if (props.page === 'facts') return 'Facts';
  return 'Overview';
});

const isYearNavEnabled = computed(() => {
  return props.page !== 'index';
});

const overviewUrl = computed(() => {
  return urls.cashgame.index(slug.value);
});

const matrixUrl = computed(() => {
  return urls.cashgame.archive('matrix', slug.value, selectedYear.value);
});

const toplistUrl = computed(() => {
  return urls.cashgame.archive('toplist', slug.value, selectedYear.value);
});

const chartUrl = computed(() => {
  return urls.cashgame.archive('chart', slug.value, selectedYear.value);
});

const listUrl = computed(() => {
  return urls.cashgame.archive('list', slug.value, selectedYear.value);
});

const factsUrl = computed(() => {
  return urls.cashgame.archive('facts', slug.value, selectedYear.value);
});

const isOverviewSelected = computed(() => {
  return props.page === 'index';
});

const isMatrixSelected = computed(() => {
  return props.page === 'matrix';
});

const isToplistSelected = computed(() => {
  return props.page === 'toplist';
});

const isChartSelected = computed(() => {
  return props.page === 'chart';
});

const isListSelected = computed(() => {
  return props.page === 'list';
});

const isFactsSelected = computed(() => {
  return props.page === 'facts';
});

const onSelected = (url: string) => {
  if (route.fullPath !== url) router.push(url);
};

const onSelectedYear = () => {
  router.push(urls.cashgame.archive(props.page, slug.value, selectedYear.value));
};

onMounted(() => {
  selectedYear.value = year.value || currentYear.value;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, _toDisplayString(selectedPageName.value), 1 /* TEXT */),
    _createElementVNode("ul", null, [
      _createVNode(CashgameNavigationItem, {
        url: overviewUrl.value,
        text: "Overview",
        isSelected: isOverviewSelected.value,
        onSelected: onSelected
      }, null, 8 /* PROPS */, ["url", "isSelected"]),
      _createVNode(CashgameNavigationItem, {
        url: matrixUrl.value,
        text: "Matrix",
        isSelected: isMatrixSelected.value,
        onSelected: onSelected
      }, null, 8 /* PROPS */, ["url", "isSelected"]),
      _createVNode(CashgameNavigationItem, {
        url: toplistUrl.value,
        text: "Toplist",
        isSelected: isToplistSelected.value,
        onSelected: onSelected
      }, null, 8 /* PROPS */, ["url", "isSelected"]),
      _createVNode(CashgameNavigationItem, {
        url: chartUrl.value,
        text: "Chart",
        isSelected: isChartSelected.value,
        onSelected: onSelected
      }, null, 8 /* PROPS */, ["url", "isSelected"]),
      _createVNode(CashgameNavigationItem, {
        url: listUrl.value,
        text: "List",
        isSelected: isListSelected.value,
        onSelected: onSelected
      }, null, 8 /* PROPS */, ["url", "isSelected"]),
      _createVNode(CashgameNavigationItem, {
        url: factsUrl.value,
        text: "Facts",
        isSelected: isFactsSelected.value,
        onSelected: onSelected
      }, null, 8 /* PROPS */, ["url", "isSelected"])
    ]),
    (isYearNavEnabled.value)
      ? (_openBlock(), _createBlock(YearDropdown, {
          key: 0,
          class: "cashgame-navigation__year-dropdown",
          modelValue: selectedYear.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectedYear).value = $event)),
          years: _unref(years),
          onInput: onSelectedYear
        }, null, 8 /* PROPS */, ["modelValue", "years"]))
      : _createCommentVNode("v-if", true)
  ]))
}
}

})