import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page-heading" }


export default /*@__PURE__*/_defineComponent({
  __name: 'PageHeading',
  props: {
    text: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("h1", _hoisted_1, _toDisplayString(_ctx.text), 1 /* TEXT */))
}
}

})