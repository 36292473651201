import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "matrix"
}

import MatrixColumn from './MatrixColumn.vue';
import MatrixRow from './MatrixRow.vue';
import { TableList, TableListColumnHeader } from '@/components/Common/TableList';
import { ArchiveCashgame } from '@/models/ArchiveCashgame';
import archiveHelper from '@/ArchiveHelper';
import playerSorter from '@/PlayerSorter';
import { computed } from 'vue';
import { Localization } from '@/models/Localization';


export default /*@__PURE__*/_defineComponent({
  __name: 'MatrixTable',
  props: {
    slug: {},
    games: {},
    localization: {}
  },
  setup(__props: any) {

const props = __props;

const hasGames = computed(() => props.games.length > 0);
const players = computed(() => playerSorter.sort(archiveHelper.getPlayers(props.games)));

return (_ctx: any,_cache: any) => {
  return (hasGames.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_unref(TableList), null, {
          default: _withCtx(() => [
            _createElementVNode("thead", null, [
              _createElementVNode("tr", null, [
                _createVNode(_unref(TableListColumnHeader)),
                _createVNode(_unref(TableListColumnHeader), null, {
                  default: _withCtx(() => _cache[0] || (_cache[0] = [
                    _createTextVNode("Player")
                  ])),
                  _: 1 /* STABLE */
                }),
                _createVNode(_unref(TableListColumnHeader), null, {
                  default: _withCtx(() => _cache[1] || (_cache[1] = [
                    _createTextVNode("Winnings")
                  ])),
                  _: 1 /* STABLE */
                }),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.games, (game) => {
                  return (_openBlock(), _createBlock(MatrixColumn, {
                    game: game,
                    slug: _ctx.slug,
                    key: game.id
                  }, null, 8 /* PROPS */, ["game", "slug"]))
                }), 128 /* KEYED_FRAGMENT */))
              ])
            ]),
            _createElementVNode("tbody", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(players.value, (player, index) => {
                return (_openBlock(), _createBlock(MatrixRow, {
                  player: player,
                  index: index,
                  bunchId: _ctx.slug,
                  localization: _ctx.localization,
                  key: player.id
                }, null, 8 /* PROPS */, ["player", "index", "bunchId", "localization"]))
              }), 128 /* KEYED_FRAGMENT */))
            ])
          ]),
          _: 1 /* STABLE */
        })
      ]))
    : _createCommentVNode("v-if", true)
}
}

})