import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

import api from '@/api';
import { CustomButton } from '@/components/Common';
import { MessageResponse } from '@/response/MessageResponse';
import { useMutation } from '@tanstack/vue-query';
import { computed, ref } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'SendEmail',
  setup(__props) {

const showMessage = (m: string) => {
  message.value = m;
  setTimeout(clearMessage, 3000);
};

const onComplete = (response: MessageResponse) => showMessage(response.message);
const message = ref<string | null>(null);
const hasMessage = computed(() => !!message.value);
const clearMessage = () => (message.value = null);

const { mutateAsync: sendEmail } = useMutation({
  mutationFn: async (): Promise<MessageResponse> => {
    var response = await api.sendEmail();
    return response.data;
  },
  onSuccess: onComplete,
  onError: onComplete,
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _cache[0] || (_cache[0] = _createElementVNode("h2", { class: "module-heading" }, "Send test email", -1 /* HOISTED */)),
    _createElementVNode("p", null, [
      _createVNode(_unref(CustomButton), {
        text: "Send",
        type: "action",
        onClick: _unref(sendEmail)
      }, null, 8 /* PROPS */, ["onClick"])
    ]),
    (hasMessage.value)
      ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(message.value), 1 /* TEXT */))
      : _createCommentVNode("v-if", true)
  ]))
}
}

})