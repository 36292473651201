import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import urls from '@/urls';
import { CustomLink } from '@/components/Common';
import { ArchiveCashgame } from '@/models/ArchiveCashgame';
import format from '@/format';
import { TableListCell, TableListRow } from '@/components/Common/TableList';
import { computed } from 'vue';
import { BunchResponse } from '@/response/BunchResponse';
import { Localization } from '@/models/Localization';


export default /*@__PURE__*/_defineComponent({
  __name: 'GameListRow',
  props: {
    bunch: {},
    game: {},
    localization: {}
  },
  setup(__props: any) {

const props = __props;

const url = computed(() => {
  return urls.cashgame.details(props.bunch.id, props.game.id);
});

const displayDate = computed(() => {
  return format.monthDay(props.game.date);
});

const duration = computed(() => {
  return format.duration(props.game.duration);
});

const formattedAverageBuyin = computed(() => {
  return format.currency(props.game.averageBuyin, props.localization);
});

const formattedTurnover = computed(() => {
  return format.currency(props.game.turnover, props.localization);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(TableListRow), null, {
    default: _withCtx(() => [
      _createVNode(_unref(TableListCell), null, {
        default: _withCtx(() => [
          _createVNode(_unref(CustomLink), { url: url.value }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(displayDate.value), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["url"])
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_unref(TableListCell), { "is-numeric": true }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.game.playerCount), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_unref(TableListCell), null, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.game.location.name), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_unref(TableListCell), null, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(duration.value), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_unref(TableListCell), { "is-numeric": true }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(formattedTurnover.value), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_unref(TableListCell), { "is-numeric": true }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(formattedAverageBuyin.value), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }))
}
}

})