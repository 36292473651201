import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

import { Layout } from '@/components/Layouts';
import { Block, PageHeading, PageSection } from '@/components/Common';
import BunchList from '@/components/BunchList/BunchList.vue';
import { computed } from 'vue';
import { useBunchList, useCurrentUser } from '@/composables';


export default /*@__PURE__*/_defineComponent({
  __name: 'BunchListPage',
  setup(__props) {

const { bunches, bunchesReady } = useBunchList();
const { isAdmin, currentUserReady } = useCurrentUser();

const ready = computed(() => {
  return currentUserReady.value && bunchesReady.value;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Layout), {
    "require-user": true,
    ready: ready.value
  }, {
    default: _withCtx(() => [
      _createVNode(_unref(PageSection), null, {
        default: _withCtx(() => [
          _createVNode(_unref(Block), null, {
            default: _withCtx(() => [
              _createVNode(_unref(PageHeading), { text: "Bunches" })
            ]),
            _: 1 /* STABLE */
          }),
          (_unref(isAdmin))
            ? (_openBlock(), _createBlock(_unref(Block), { key: 0 }, {
                default: _withCtx(() => [
                  _createVNode(BunchList, { bunches: _unref(bunches) }, null, 8 /* PROPS */, ["bunches"])
                ]),
                _: 1 /* STABLE */
              }))
            : (_openBlock(), _createBlock(_unref(Block), { key: 1 }, {
                default: _withCtx(() => _cache[0] || (_cache[0] = [
                  _createTextVNode(" Access denied ")
                ])),
                _: 1 /* STABLE */
              }))
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["ready"]))
}
}

})