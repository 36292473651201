import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = ["value"]
const _hoisted_2 = ["value"]

import { computed } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'CurrencyLayoutDropdown',
  props: {
    modelValue: {},
    symbol: {}
  },
  emits: ['update:modelValue'],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const symbol = computed(() => {
  return props.symbol ?? '$';
});

const currencyLayouts = computed(() => {
  return ['{SYMBOL} {AMOUNT}', '{SYMBOL}{AMOUNT}', '{AMOUNT}{SYMBOL}', '{AMOUNT} {SYMBOL}'];
});

const getDisplayName = (layout: string) => {
  return layout.replace('{SYMBOL}', symbol.value).replace('{AMOUNT}', '123');
};

const updateValue = (event: Event) => {
  const value = (event.target as HTMLInputElement).value;
  emit('update:modelValue', value);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("select", {
    value: _ctx.modelValue,
    onInput: updateValue
  }, [
    _cache[0] || (_cache[0] = _createElementVNode("option", { value: "" }, "Select layout", -1 /* HOISTED */)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(currencyLayouts.value, (currencyLayout) => {
      return (_openBlock(), _createElementBlock("option", {
        value: currencyLayout,
        key: currencyLayout
      }, _toDisplayString(getDisplayName(currencyLayout)), 9 /* TEXT, PROPS */, _hoisted_2))
    }), 128 /* KEYED_FRAGMENT */))
  ], 40 /* PROPS, NEED_HYDRATION */, _hoisted_1))
}
}

})