import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, unref as _unref, withCtx as _withCtx, createBlock as _createBlock } from "vue"

import { SimpleList, SimpleListItem } from '@/components/Common/SimpleList';
import PlayerListItem from './PlayerListItem.vue';
import { Player } from '@/models/Player';


export default /*@__PURE__*/_defineComponent({
  __name: 'PlayerList',
  props: {
    bunchId: {},
    players: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(SimpleList), null, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.players, (player) => {
        return (_openBlock(), _createBlock(_unref(SimpleListItem), {
          key: player.id
        }, {
          default: _withCtx(() => [
            _createVNode(PlayerListItem, {
              id: player.id,
              name: player.name,
              color: player.color,
              bunchId: _ctx.bunchId
            }, null, 8 /* PROPS */, ["id", "name", "color", "bunchId"])
          ]),
          _: 2 /* DYNAMIC */
        }, 1024 /* DYNAMIC_SLOTS */))
      }), 128 /* KEYED_FRAGMENT */))
    ]),
    _: 1 /* STABLE */
  }))
}
}

})