import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { Layout } from '@/components/Layouts';
import BunchNavigation from '@/components/Navigation/BunchNavigation.vue';
import CashgameNavigation from '@/components/Navigation/CashgameNavigation.vue';
import MatrixTable from '@/components/Matrix/MatrixTable.vue';
import { Block, PageSection } from '@/components/Common';
import { computed } from 'vue';
import { useGameList, useParams, useBunch } from '@/composables';


export default /*@__PURE__*/_defineComponent({
  __name: 'MatrixPage',
  setup(__props) {

const { slug, year } = useParams();
const { localization, bunchReady } = useBunch(slug.value);
const { getSelectedGames, gamesReady } = useGameList(slug.value);

const games = computed(() => getSelectedGames(year.value));
const ready = computed(() => gamesReady.value);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Layout), {
    "require-user": true,
    ready: ready.value
  }, {
    "top-nav": _withCtx(() => [
      _createVNode(BunchNavigation)
    ]),
    default: _withCtx(() => [
      _createVNode(_unref(PageSection), null, {
        default: _withCtx(() => [
          _createVNode(_unref(Block), null, {
            default: _withCtx(() => [
              _createVNode(CashgameNavigation, { page: "matrix" })
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_unref(PageSection), null, {
        default: _withCtx(() => [
          _createVNode(_unref(Block), null, {
            default: _withCtx(() => [
              _createVNode(MatrixTable, {
                slug: _unref(slug),
                games: games.value,
                localization: _unref(localization)
              }, null, 8 /* PROPS */, ["slug", "games", "localization"])
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["ready"]))
}
}

})