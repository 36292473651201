import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { Layout } from '@/components/Layouts';
import BunchNavigation from '@/components/Navigation/BunchNavigation.vue';
import { Block, PageHeading, PageSection } from '@/components/Common';
import MatrixTable from '@/components/Matrix/MatrixTable.vue';
import { computed } from 'vue';
import { useParams, useBunch, useEventList, useEventGameList } from '@/composables';


export default /*@__PURE__*/_defineComponent({
  __name: 'EventDetailsPage',
  setup(__props) {

const { slug, eventId } = useParams();
const { getEvent, eventsReady } = useEventList(slug.value);
const { localization, bunchReady } = useBunch(slug.value);
const { eventGames, eventGamesReady } = useEventGameList(slug.value, eventId.value);

const name = computed(() => {
  if (event.value) return event.value.name;
  return '';
});

const event = computed(() => {
  return getEvent(eventId.value);
});

const ready = computed(() => {
  return bunchReady.value && eventsReady.value && eventGamesReady.value;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Layout), {
    "require-user": true,
    ready: ready.value
  }, {
    "top-nav": _withCtx(() => [
      _createVNode(BunchNavigation)
    ]),
    default: _withCtx(() => [
      _createVNode(_unref(PageSection), null, {
        default: _withCtx(() => [
          _createVNode(_unref(Block), null, {
            default: _withCtx(() => [
              _createVNode(_unref(PageHeading), { text: name.value }, null, 8 /* PROPS */, ["text"])
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_unref(Block), null, {
            default: _withCtx(() => [
              _createVNode(MatrixTable, {
                slug: _unref(slug),
                games: _unref(eventGames),
                localization: _unref(localization)
              }, null, 8 /* PROPS */, ["slug", "games", "localization"])
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["ready"]))
}
}

})