import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form" }
const _hoisted_2 = { class: "field" }
const _hoisted_3 = { class: "buttons" }

import validate from '@/validate';
import forms from '@/forms';
import { CustomButton } from '@/components/Common';
import { computed, onMounted, ref } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'CashoutForm',
  props: {
    suggestedCashout: {}
  },
  emits: ['cashout', 'cancel'],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const strStack = ref('0');
const stackError = ref<string | null>(null);

const hasErrors = computed(() => {
  return stackError.value === null;
});

const stack = computed(() => {
  return forms.parseInt(strStack.value);
});

const cashout = () => {
  validateForm();
  if (!hasErrors.value) emit('cashout', stack.value);
};

const cancel = () => {
  emit('cancel');
};

const focus = (e: FocusEvent) => {
  if (e.target) forms.selectAll(e.target as HTMLInputElement);
};

const validateForm = () => {
  clearErrors();
  if (validate.intRange(stack.value, 0)) stackError.value = "Stack can't be negative";
};

const clearErrors = () => {
  stackError.value = null;
};

onMounted(() => {
  strStack.value = props.suggestedCashout.toString();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[1] || (_cache[1] = _createElementVNode("label", {
        class: "label",
        for: "cashout-stack"
      }, "Stack Size", -1 /* HOISTED */)),
      _withDirectives(_createElementVNode("input", {
        class: "numberfield",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((strStack).value = $event)),
        onFocus: focus,
        id: "cashout-stack",
        type: "text",
        inputmode: "numeric",
        pattern: "[0-9]*"
      }, null, 544 /* NEED_HYDRATION, NEED_PATCH */), [
        [_vModelText, strStack.value]
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_unref(CustomButton), {
        onClick: cashout,
        type: "action",
        text: "Cash Out"
      }),
      _createVNode(_unref(CustomButton), {
        onClick: cancel,
        text: "Cancel"
      })
    ])
  ]))
}
}

})