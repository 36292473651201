import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { CustomLink } from '@/components/Common';
import urls from '@/urls';
import { CashgameListPlayerData } from '@/models/CashgameListPlayerData';
import { TableListCell, TableListRow } from '@/components/Common/TableList';
import { CurrencyText, DurationText, WinningsText, WinrateText } from '@/components/Common';
import { computed } from 'vue';
import { Localization } from '@/models/Localization';


export default /*@__PURE__*/_defineComponent({
  __name: 'TopListRow',
  props: {
    bunchId: {},
    player: {},
    localization: {}
  },
  setup(__props: any) {

const props = __props;

const url = computed(() => {
  return urls.player.details(props.bunchId, props.player.id);
});

const winnings = computed(() => {
  return props.player.winnings;
});

const buyin = computed(() => {
  return props.player.buyin;
});

const cashout = computed(() => {
  return props.player.stack;
});

const winrate = computed(() => {
  return props.player.winrate;
});

const time = computed(() => {
  return props.player.playedTimeInMinutes;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(TableListRow), null, {
    default: _withCtx(() => [
      _createVNode(_unref(TableListCell), { "is-numeric": true }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.player.rank) + ".", 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_unref(TableListCell), null, {
        default: _withCtx(() => [
          _createVNode(_unref(CustomLink), { url: url.value }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.player.name), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["url"])
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_unref(TableListCell), { "is-numeric": true }, {
        default: _withCtx(() => [
          _createVNode(_unref(WinningsText), {
            value: winnings.value,
            "show-currency": true,
            localization: _ctx.localization
          }, null, 8 /* PROPS */, ["value", "localization"])
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_unref(TableListCell), { "is-numeric": true }, {
        default: _withCtx(() => [
          _createVNode(_unref(CurrencyText), {
            value: buyin.value,
            localization: _ctx.localization
          }, null, 8 /* PROPS */, ["value", "localization"])
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_unref(TableListCell), { "is-numeric": true }, {
        default: _withCtx(() => [
          _createVNode(_unref(CurrencyText), {
            value: cashout.value,
            localization: _ctx.localization
          }, null, 8 /* PROPS */, ["value", "localization"])
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_unref(TableListCell), null, {
        default: _withCtx(() => [
          _createVNode(_unref(DurationText), { value: time.value }, null, 8 /* PROPS */, ["value"])
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_unref(TableListCell), { "is-numeric": true }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.player.gameCount), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_unref(TableListCell), { "is-numeric": true }, {
        default: _withCtx(() => [
          _createVNode(_unref(WinrateText), {
            value: winrate.value,
            localization: _ctx.localization
          }, null, 8 /* PROPS */, ["value", "localization"])
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }))
}
}

})