import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = ["value"]
const _hoisted_2 = ["value"]

import { EventResponse } from '@/response/EventResponse';


export default /*@__PURE__*/_defineComponent({
  __name: 'EventDropdown',
  props: {
    modelValue: {},
    events: {}
  },
  emits: ['update:modelValue'],
  setup(__props: any, { emit: __emit }) {



const emit = __emit;

const updateValue = (event: Event) => {
  const value = (event.target as HTMLInputElement).value;
  emit('update:modelValue', value);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("select", {
    value: _ctx.modelValue,
    onInput: updateValue
  }, [
    _cache[0] || (_cache[0] = _createElementVNode("option", { value: "" }, "Select Event", -1 /* HOISTED */)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.events, (event) => {
      return (_openBlock(), _createElementBlock("option", {
        value: event.id,
        key: event.id
      }, _toDisplayString(event.name), 9 /* TEXT, PROPS */, _hoisted_2))
    }), 128 /* KEYED_FRAGMENT */))
  ], 40 /* PROPS, NEED_HYDRATION */, _hoisted_1))
}
}

})