import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { CustomLink } from '@/components/Common';
import { SimpleList, SimpleListItem } from '@/components/Common/SimpleList';
import urls from '@/urls';
import { computed } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'AdminNavigation',
  setup(__props) {

const bunchesUrl = computed(() => {
  return urls.bunch.list;
});

const usersUrl = computed(() => {
  return urls.user.list;
});

const toolsUrl = computed(() => {
  return urls.admin.tools;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("nav", null, [
    _cache[3] || (_cache[3] = _createElementVNode("h1", { class: "module-heading" }, "Admin", -1 /* HOISTED */)),
    _createVNode(_unref(SimpleList), null, {
      default: _withCtx(() => [
        _createVNode(_unref(SimpleListItem), null, {
          default: _withCtx(() => [
            _createVNode(_unref(CustomLink), { url: bunchesUrl.value }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createElementVNode("span", null, "Bunches", -1 /* HOISTED */)
              ])),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["url"])
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_unref(SimpleListItem), null, {
          default: _withCtx(() => [
            _createVNode(_unref(CustomLink), { url: usersUrl.value }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createElementVNode("span", null, "Users", -1 /* HOISTED */)
              ])),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["url"])
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_unref(SimpleListItem), null, {
          default: _withCtx(() => [
            _createVNode(_unref(CustomLink), { url: toolsUrl.value }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createElementVNode("span", null, "Tools", -1 /* HOISTED */)
              ])),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["url"])
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    })
  ]))
}
}

})