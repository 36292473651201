import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["value"]
const _hoisted_3 = ["value"]
const _hoisted_4 = { key: 0 }
const _hoisted_5 = ["value"]
const _hoisted_6 = { key: 1 }

import { DetailedCashgameResponseActionType } from '@/response/DetailedCashgameResponseActionType';
import format from '@/format';
import { computed, ref } from 'vue';
import { Localization } from '@/models/Localization';
import { DetailedCashgameAction } from '@/models/DetailedCashgameAction';
import { SaveActionEmitData } from '@/models/SaveActionEmitData';


export default /*@__PURE__*/_defineComponent({
  __name: 'PlayerAction',
  props: {
    action: {},
    localization: {},
    canEdit: { type: Boolean }
  },
  emits: ["saveAction", "deleteAction"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const isFormVisible = ref(false);
const changedTime = ref<string | null>(null);
const changedStack = ref<number | null>(null);
const changedAdded = ref<number | null>(null);

const formTime = computed(() => {
  if (changedTime.value !== null) return changedTime.value;
  return format.isoTime(props.action.time);
});

const formStack = computed(() => {
  if (changedStack.value !== null) return changedStack.value;
  return props.action.stack;
});

const formAdded = computed(() => {
  if (changedAdded.value !== null) return changedAdded.value;
  return props.action.added;
});

const formattedTime = computed(() => {
  return format.hourMinute(props.action.time);
});

const formattedAmount = computed(() => {
  return format.currency(amount.value, props.localization);
});

const amount = computed(() => {
  if (props.action.type === DetailedCashgameResponseActionType.Buyin && props.action.added) return props.action.added;
  return props.action.stack;
});

const typeName = computed((): string => {
  if (props.action.type === DetailedCashgameResponseActionType.Buyin) return 'Buyin';
  if (props.action.type === DetailedCashgameResponseActionType.Cashout) return 'Cashout';
  return 'Report';
});

const showAddedField = computed(() => {
  return props.action.type === DetailedCashgameResponseActionType.Buyin;
});

const clickEdit = () => {
  showForm();
};

const clickDelete = () => {
  if (props.action.id && window.confirm('Do you want to delete this action?')) {
    emit('deleteAction', props.action.id);
  }
};

const clickCancel = () => {
  hideForm();
};

const clickSave = () => {
  const data: SaveActionEmitData = {
    id: props.action.id,
    time: formTime.value,
    stack: formStack.value,
    added: formAdded.value,
  };
  emit('saveAction', data);
  hideForm();
  changedTime.value = null;
  changedStack.value = null;
  changedAdded.value = null;
};

const showForm = () => {
  isFormVisible.value = true;
};

const hideForm = () => {
  isFormVisible.value = false;
};

const updateTime = (e: Event) => {
  const el = e.target as HTMLInputElement;
  changedTime.value = el.value;
};

const updateStack = (e: Event) => {
  const el = e.target as HTMLInputElement;
  changedStack.value = parseInt(el.value);
};

const updateAdded = (e: Event) => {
  const el = e.target as HTMLInputElement;
  changedAdded.value = parseInt(el.value);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (isFormVisible.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", null, "type: " + _toDisplayString(_ctx.action.type), 1 /* TEXT */),
          _createElementVNode("div", null, [
            _cache[0] || (_cache[0] = _createTextVNode("time: ")),
            _createElementVNode("input", {
              type: "text",
              value: formTime.value,
              onInput: updateTime
            }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_2)
          ]),
          _createElementVNode("div", null, [
            _cache[1] || (_cache[1] = _createTextVNode("stack: ")),
            _createElementVNode("input", {
              type: "text",
              value: formStack.value,
              onInput: updateStack
            }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_3)
          ]),
          (showAddedField.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _cache[2] || (_cache[2] = _createTextVNode("added: ")),
                _createElementVNode("input", {
                  type: "text",
                  value: formAdded.value,
                  onInput: updateAdded
                }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_5)
              ]))
            : _createCommentVNode("v-if", true),
          _createElementVNode("div", null, [
            _createElementVNode("button", { onClick: clickCancel }, "Cancel"),
            _createElementVNode("button", { onClick: clickSave }, "Save")
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createTextVNode(_toDisplayString(formattedTime.value) + " " + _toDisplayString(typeName.value) + ": " + _toDisplayString(formattedAmount.value) + " ", 1 /* TEXT */),
          (_ctx.canEdit)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                onClick: clickEdit
              }, "Edit"))
            : _createCommentVNode("v-if", true),
          (_ctx.canEdit)
            ? (_openBlock(), _createElementBlock("button", {
                key: 1,
                onClick: clickDelete
              }, "Delete"))
            : _createCommentVNode("v-if", true)
        ]))
  ]))
}
}

})