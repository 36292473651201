import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "game-list" }
const _hoisted_2 = { class: "list" }

import GameListRow from './GameListRow.vue';
import { TableList, TableListColumnHeader } from '@/components/Common/TableList';
import { computed, ref } from 'vue';
import { BunchResponse } from '@/response/BunchResponse';
import { ArchiveCashgame } from '@/models/ArchiveCashgame';
import gameSorter from '@/GameSorter';
import { CashgameSortOrder } from '@/models/CashgameSortOrder';
import { Localization } from '@/models/Localization';


export default /*@__PURE__*/_defineComponent({
  __name: 'GameListTable',
  props: {
    bunch: {},
    games: {},
    localization: {}
  },
  setup(__props: any) {

const props = __props;

const orderedBy = ref(CashgameSortOrder.Date);

const sortedGames = computed(() => {
  return gameSorter.sort(props.games, orderedBy.value);
});

const sort = (column: CashgameSortOrder) => {
  orderedBy.value = column;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(TableList), null, {
      default: _withCtx(() => [
        _createElementVNode("thead", null, [
          _createElementVNode("tr", null, [
            _createVNode(_unref(TableListColumnHeader), {
              "ordered-by": orderedBy.value,
              onSort: sort,
              "sort-name": "date"
            }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode("Date")
              ])),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["ordered-by"]),
            _createVNode(_unref(TableListColumnHeader), {
              "ordered-by": orderedBy.value,
              onSort: sort,
              "sort-name": "playercount"
            }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createTextVNode("Players")
              ])),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["ordered-by"]),
            _createVNode(_unref(TableListColumnHeader), null, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createTextVNode("Location")
              ])),
              _: 1 /* STABLE */
            }),
            _createVNode(_unref(TableListColumnHeader), {
              "ordered-by": orderedBy.value,
              onSort: sort,
              "sort-name": "duration"
            }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode("Duration")
              ])),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["ordered-by"]),
            _createVNode(_unref(TableListColumnHeader), {
              "ordered-by": orderedBy.value,
              onSort: sort,
              "sort-name": "turnover"
            }, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createTextVNode("Turnover")
              ])),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["ordered-by"]),
            _createVNode(_unref(TableListColumnHeader), {
              "ordered-by": orderedBy.value,
              onSort: sort,
              "sort-name": "averagebuyin"
            }, {
              default: _withCtx(() => _cache[5] || (_cache[5] = [
                _createTextVNode(" Average Buyin ")
              ])),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["ordered-by"])
          ])
        ]),
        _createElementVNode("tbody", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sortedGames.value, (game) => {
            return (_openBlock(), _createBlock(GameListRow, {
              game: game,
              bunch: _ctx.bunch,
              localization: _ctx.localization,
              key: game.id
            }, null, 8 /* PROPS */, ["game", "bunch", "localization"]))
          }), 128 /* KEYED_FRAGMENT */))
        ])
      ]),
      _: 1 /* STABLE */
    })
  ]))
}
}

})