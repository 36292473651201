import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, unref as _unref, withCtx as _withCtx, createBlock as _createBlock } from "vue"

import { SimpleList, SimpleListItem } from '@/components/Common/SimpleList';
import LocationListItem from '@/components/LocationList/LocationListItem.vue';
import comparer from '@/comparer';
import { LocationResponse } from '@/response/LocationResponse';
import { computed } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'LocationList',
  props: {
    slug: {},
    locations: {}
  },
  setup(__props: any) {

const props = __props;

const locationList = computed(() => {
  return props.locations.slice().sort(compareLocation);
});

const compareLocation = (a: LocationResponse, b: LocationResponse) => {
  return comparer.compare(a.name, b.name);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(SimpleList), null, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(locationList.value, (location) => {
        return (_openBlock(), _createBlock(_unref(SimpleListItem), {
          key: location.id
        }, {
          default: _withCtx(() => [
            _createVNode(LocationListItem, {
              "bunch-id": _ctx.slug,
              location: location
            }, null, 8 /* PROPS */, ["bunch-id", "location"])
          ]),
          _: 2 /* DYNAMIC */
        }, 1024 /* DYNAMIC_SLOTS */))
      }), 128 /* KEYED_FRAGMENT */))
    ]),
    _: 1 /* STABLE */
  }))
}
}

})