import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

import { CssClasses } from '@/models/CssClasses';
import { computed, useSlots } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'PageSection',
  props: {
    isWide: { type: Boolean, default: false }
  },
  setup(__props: any) {

const props = __props;

const slots = useSlots();

const isAside1Enabled = computed(() => {
  return isSlotEnabled('aside1');
});

const isAside2Enabled = computed(() => {
  return isSlotEnabled('aside2');
});

const hasAside = computed(() => {
  return isAside1Enabled.value || isAside2Enabled.value;
});

const cssClasses = computed((): CssClasses => {
  return {
    'page-section': true,
    'page-section--wide': props.isWide,
  };
});

const asideCssClasses = computed((): CssClasses => {
  return {
    region: true,
    aside: true,
  };
});

const mainCssClasses = computed((): CssClasses => {
  return {
    region: true,
    width2: hasAside.value,
  };
});

const isSlotEnabled = (name: string) => {
  return !!slots[name];
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(cssClasses.value)
  }, [
    (isAside1Enabled.value)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(asideCssClasses.value)
        }, [
          _renderSlot(_ctx.$slots, "aside1")
        ], 2 /* CLASS */))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", {
      class: _normalizeClass(mainCssClasses.value)
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 2 /* CLASS */),
    (isAside2Enabled.value)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(asideCssClasses.value)
        }, [
          _renderSlot(_ctx.$slots, "aside2")
        ], 2 /* CLASS */))
      : _createCommentVNode("v-if", true)
  ], 2 /* CLASS */))
}
}

})