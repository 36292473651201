import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

import { Layout } from '@/components/Layouts';
import AdminNavigation from '@/components/Navigation/AdminNavigation.vue';
import BunchNavigation from '@/components/Navigation/BunchNavigation.vue';
import urls from '@/urls';
import { Block, CustomLink, PageHeading, PageSection } from '@/components/Common';
import UserBunchList from '@/components/UserBunchList/UserBunchList.vue';
import { computed } from 'vue';
import { useUserBunchList, useCurrentUser } from '@/composables';


export default /*@__PURE__*/_defineComponent({
  __name: 'HomePage',
  setup(__props) {

const { isSignedIn, isAdmin, currentUserReady } = useCurrentUser();
const { userBunchesReady, userBunches } = useUserBunchList(isSignedIn.value);

const loginUrl = computed(() => urls.auth.login);
const registerUrl = computed(() => urls.user.add);
const addBunchUrl = computed(() => urls.bunch.add);
const apiDocsUrl = computed(() => urls.api.docs);

const ready = computed(() => {
  return currentUserReady.value && userBunchesReady.value;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Layout), {
    "require-user": false,
    ready: ready.value
  }, {
    "top-nav": _withCtx(() => [
      _createVNode(BunchNavigation)
    ]),
    default: _withCtx(() => [
      _createVNode(_unref(PageSection), null, {
        default: _withCtx(() => [
          _createVNode(_unref(Block), null, {
            default: _withCtx(() => [
              _createVNode(_unref(PageHeading), { text: "This is Poker Bunch" })
            ]),
            _: 1 /* STABLE */
          }),
          (_unref(isSignedIn))
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_unref(Block), null, {
                  default: _withCtx(() => [
                    _createElementVNode("p", null, [
                      _cache[1] || (_cache[1] = _createTextVNode(" Poker Bunch helps you keep track of the results in your poker homegames. Please select one of your bunches, or ")),
                      _createVNode(_unref(CustomLink), { url: addBunchUrl.value }, {
                        default: _withCtx(() => _cache[0] || (_cache[0] = [
                          _createTextVNode("create a new bunch")
                        ])),
                        _: 1 /* STABLE */
                      }, 8 /* PROPS */, ["url"]),
                      _cache[2] || (_cache[2] = _createTextVNode(". "))
                    ]),
                    _cache[3] || (_cache[3] = _createElementVNode("p", null, "If you want to join an existing bunch, you will need an invitation from a bunch player.", -1 /* HOISTED */))
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_unref(Block), null, {
                  default: _withCtx(() => [
                    _cache[7] || (_cache[7] = _createElementVNode("h2", { class: "module-heading" }, "Api", -1 /* HOISTED */)),
                    _createElementVNode("p", null, [
                      _cache[5] || (_cache[5] = _createTextVNode(" The ")),
                      _createVNode(_unref(CustomLink), { url: apiDocsUrl.value }, {
                        default: _withCtx(() => _cache[4] || (_cache[4] = [
                          _createTextVNode("api")
                        ])),
                        _: 1 /* STABLE */
                      }, 8 /* PROPS */, ["url"]),
                      _cache[6] || (_cache[6] = _createTextVNode(" makes it possible to create your own apps that interact with Poker Bunch. "))
                    ])
                  ]),
                  _: 1 /* STABLE */
                })
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_unref(Block), null, {
                  default: _withCtx(() => _cache[8] || (_cache[8] = [
                    _createElementVNode("p", null, "Poker Bunch helps you keep track of the results in your poker homegames.", -1 /* HOISTED */)
                  ])),
                  _: 1 /* STABLE */
                }),
                _createVNode(_unref(Block), null, {
                  default: _withCtx(() => [
                    _createElementVNode("p", null, [
                      _createVNode(_unref(CustomLink), { url: loginUrl.value }, {
                        default: _withCtx(() => _cache[9] || (_cache[9] = [
                          _createTextVNode("Sign in")
                        ])),
                        _: 1 /* STABLE */
                      }, 8 /* PROPS */, ["url"]),
                      _cache[11] || (_cache[11] = _createTextVNode(" if you already have an account, or ")),
                      _createVNode(_unref(CustomLink), { url: registerUrl.value }, {
                        default: _withCtx(() => _cache[10] || (_cache[10] = [
                          _createTextVNode("register")
                        ])),
                        _: 1 /* STABLE */
                      }, 8 /* PROPS */, ["url"]),
                      _cache[12] || (_cache[12] = _createTextVNode(" to create a bunch and begin inviting players. "))
                    ])
                  ]),
                  _: 1 /* STABLE */
                })
              ]))
        ]),
        aside2: _withCtx(() => [
          _createVNode(_unref(Block), null, {
            default: _withCtx(() => [
              _createVNode(UserBunchList, { bunches: _unref(userBunches) }, null, 8 /* PROPS */, ["bunches"])
            ]),
            _: 1 /* STABLE */
          }),
          (_unref(isAdmin))
            ? (_openBlock(), _createBlock(_unref(Block), { key: 0 }, {
                default: _withCtx(() => [
                  _createVNode(AdminNavigation)
                ]),
                _: 1 /* STABLE */
              }))
            : _createCommentVNode("v-if", true)
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["ready"]))
}
}

})