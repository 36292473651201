import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { DefinitionList, DefinitionData, DefinitionTerm } from '@/components/Common/DefinitionList';
import { CurrencyText, DurationText } from '@/components/Common';
import { ArchiveCashgame } from '@/models/ArchiveCashgame';
import { OverallFactCollection } from '@/models/OverallFactCollection';
import { computed } from 'vue';
import { Localization } from '@/models/Localization';


export default /*@__PURE__*/_defineComponent({
  __name: 'OverallFacts',
  props: {
    games: {},
    localization: {}
  },
  setup(__props: any) {

const props = __props;

const facts = computed(() => {
  return getFacts(props.games);
});

const gameCount = computed(() => {
  return props.games.length;
});

const turnover = computed(() => {
  return facts.value.turnover;
});

const getFacts = (games: ArchiveCashgame[]): OverallFactCollection => {
  var duration = 0;
  var turnover = 0;
  for (var gi = 0; gi < games.length; gi++) {
    var game = games[gi];
    duration += game.duration;
    turnover += game.turnover;
  }
  return {
    duration: duration,
    turnover: turnover,
  };
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _cache[3] || (_cache[3] = _createElementVNode("h2", { class: "h2" }, "Overall", -1 /* HOISTED */)),
    _createVNode(_unref(DefinitionList), null, {
      default: _withCtx(() => [
        _createVNode(_unref(DefinitionTerm), null, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode("Number of games")
          ])),
          _: 1 /* STABLE */
        }),
        _createVNode(_unref(DefinitionData), null, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(gameCount.value), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_unref(DefinitionTerm), null, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode("Total Time Played")
          ])),
          _: 1 /* STABLE */
        }),
        _createVNode(_unref(DefinitionData), null, {
          default: _withCtx(() => [
            _createVNode(_unref(DurationText), {
              value: facts.value.duration
            }, null, 8 /* PROPS */, ["value"])
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_unref(DefinitionTerm), null, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [
            _createTextVNode("Total Turnover")
          ])),
          _: 1 /* STABLE */
        }),
        _createVNode(_unref(DefinitionData), null, {
          default: _withCtx(() => [
            _createVNode(_unref(CurrencyText), {
              value: turnover.value,
              localization: _ctx.localization
            }, null, 8 /* PROPS */, ["value", "localization"])
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    })
  ]))
}
}

})